import "../mini.css";

import React, { useState, useEffect } from "react";

import Footer from "../../../utils/footer";

import axiosCall from "../../../utils/axiosCall";

import { Input, notification, Modal, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from "react-router-dom";
import { PaystackButton, usePaystackPayment } from 'react-paystack';
import Payment from "../../../assets/images/payment.svg";

import IgandoMap from "../../../assets/images/content/igando_map.png";
import Nav from "../../../utils/nav";

const EventTickets = props => {

    const Navigate = useNavigate();
    const antIcon = (<LoadingOutlined style={{ fontSize: 24, color: '#fff', marginLeft: 15 }} spin />);
    const validator = yup.object().shape({
        firstName: yup.string().required('Please tell us your first name'),
        lastName: yup.string().required('Please tell us your last name'),
        emailAddress: yup.string().email('Email is not valid').required('Email field can not be empty'),
        phoneNumber: yup.string().min(6).required('Phone number field can not be empty')
    })
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContentToShowFirst, setModalContentToShowFirst] = useState('form');
    const [fetchingVideosData, setFetchingVideosData] = useState(true);
    const [videosData, setVideosData] = useState([]);
    const { eventId } = useParams();

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            description: message
        });
    };

    const { handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValue: {
            emailAddress: "",
            password: "",
        },
        resolver: yupResolver(validator)
    });
    const paymentConfig = (amount) => {
        return {
            reference: (new Date()).getTime().toString(),
            email: "user@example.com",
            amount: amount,
            publicKey: 'pk_test_6001cfe393365d476119a4e494f32bcb1290cfea',
            firstname: 'cool',
            lastname: 'story',
        }
    }
    const onSuccess = () => {
        setModalContentToShowFirst('successful')
        // setTimeout(() => {
        // setIsModalOpen(false);
        setValue('firstName', '');
        setValue('lastName', '');
        setValue('emailAddress', '');
        setValue('phoneNumber', '');
        // }, 4000);
    }
    const onClose = () => console.log('done')
    const CheckMeFirst = () => {
        const initializePayment = usePaystackPayment(paymentConfig(500000));
        return (
            <div>
                <button className="btn_red" onClick={() => {
                    initializePayment(onSuccess, onClose)
                }}>Buy Tickets Now</button>
            </div>
        );
    }

    useEffect(() => {
        axiosCall.get(`/event/${eventId}`)
            .then(videoData => {
                if (videoData.data.statusMessage === "success") {
                    setFetchingVideosData(false);
                    setVideosData(videoData.data.message);
                } else {
                    openNotificationWithIcon('error', videoData.data.summary);
                }
            })
            .catch(err => {
                openNotificationWithIcon('error', 'An error occurred while fetching event videos. Please reload page to try again')
            })
    }, [])
    return (
        <div className="visit">
            <Spin spinning={fetchingVideosData}>
                <Nav />
                <div className="event_detail">
                    {/* <video sr/c={VisitVideo} autoPlay muted loop /> */}
                    <div className="black_overlay">

                    </div>
                    <div className="hero_section_div event_summary">
                        <h3>{videosData?.eventTitle}</h3>
                        <p>{videosData?.eventDescription}</p>
                    </div>
                </div>
                <div className="contain">
                    <div>
                        <div className="grid_2_bias mt-5">
                            <div className="grid_block_right">
                                <div className="mobile-only mobile-events-box">
                                    <h5 className="page_summary">{videosData?.eventTitle}</h5>
                                    <p>{videosData?.eventDescription}</p>
                                    <p><ion-icon name="calendar-outline"></ion-icon>: {dayjs(videosData?.eventStarts).format('dddd, MMMM D, YYYY')}</p>
                                    <p><ion-icon name="alarm-outline"></ion-icon>: {videosData?.startTime}</p>
                                    {
                                        videosData?.eventTitle === "Aloyin lapa and a Stage Play" ?
                                            <a href="https://selar.co/31og" target="_blank">Buy Tickets Now</a>
                                            :
                                            videosData?.eventTitle === "A Stage Play by Crown Troupe of Africa" ?
                                                <a href="https://selar.co/dinz" target="_blank">Buy Tickets Now</a>
                                                :
                                                videosData?.eventTitle === "Alimosho Laughs with Akpors" ?
                                                    <a href="https://selar.co/4e44" target="_blank">Buy Tickets Now</a>
                                                    :
                                                    videosData?.eventTitle === "Alimosho Laughs with Igwe Henry" ?
                                                        <a href="https://selar.co/vls4" target="_blank">Buy Tickets Now</a>
                                                        :
                                                        videosData?.eventTitle === "Sip and Paint Arts Exhibition" ?
                                                            <a href="https://selar.co/o3tm" target="_blank">Buy Tickets Now</a>
                                                            :
                                                            videosData?.eventTitle === "A Choral Performance by Bellarome Chorale" ?
                                                                <a href="https://selar.co/vv4n" target="_blank">Buy Tickets Now</a>
                                                                :
                                                                <button
                                                                    disabled={videosData?.eventPricing === "free" ? true : false}
                                                                    onClick={() => setIsModalOpen(true)}
                                                                    className="btn_red">Buy Tickets Now</button>
                                    }
                                </div>
                                <div className="desktop-only">
                                    <h5 className="page_summary">Event Description</h5>
                                    <p>{videosData?.eventDescription}</p>
                                </div>
                                <div>
                                    <h4
                                        style={{ marginBottom: 0 }}
                                        className="co_text_header">Guest Safety Is Our First Priority</h4>
                                    <p>In the thick of the ongoing sequel of COVID-19, we continue to prioritize the
                                        health and well-being of our guests, artists, staffs, and the community. We are
                                        dedicated to following all guidelines for safety while providing the best patron
                                        experience for your visit.</p>
                                </div>
                                <div className="mini_story">
                                    <img src={IgandoMap} alt="visit" />
                                    <h5 className="page_summary">88, College Street, NYSC bus stop Igando.</h5>
                                </div>
                            </div>
                            <div className="grid_block_left">
                                <div className="side_display first_display">
                                    <div className="ticket-box">
                                        <ul className="first-list">
                                            <li>
                                                <span className="first">Event Begins:</span>
                                                <span className="second">{videosData?.startTime}</span>
                                            </li>
                                            <li>
                                                <span className="first">Event Ends:</span>
                                                <span className="second">{videosData?.endTime}</span>
                                            </li>
                                            <li>
                                                <span className="first">Event Date:</span>
                                                <span className="second">{dayjs(videosData?.eventStarts).format('dddd, MMMM D, YYYY')}</span>
                                            </li>
                                            <li>
                                                <span className="first">Tickets: </span>
                                                <span className="second">{videosData?.eventPricing === "free" ? 'Free' : 'PAID'}</span>
                                            </li>
                                        </ul>
                                        {
                                            videosData?.eventTitle === "Aloyin lapa and a Stage Play" ?
                                                <a href="https://selar.co/31og" target="_blank">Buy Tickets Now</a>
                                                :
                                                videosData?.eventTitle === "A Stage Play by Crown Troupe of Africa" ?
                                                    <a href="https://selar.co/dinz" target="_blank">Buy Tickets Now</a>
                                                    :
                                                    videosData?.eventTitle === "Alimosho Laughs with Akpors" ?
                                                        <a href="https://selar.co/4e44" target="_blank">Buy Tickets Now</a>
                                                        :
                                                        videosData?.eventTitle === "Alimosho Laughs with Igwe Henry" ?
                                                            <a href="https://selar.co/vls4" target="_blank">Buy Tickets Now</a>
                                                            :
                                                            videosData?.eventTitle === "Sip and Paint Arts Exhibition" ?
                                                                <a href="https://selar.co/o3tm" target="_blank">Buy Tickets Now</a>
                                                                :
                                                                videosData?.eventTitle === "A Choral Performance by Bellarome Chorale" ?
                                                                    <a href="https://selar.co/vv4n" target="_blank">Buy Tickets Now</a>
                                                                    :
                                                                    <button
                                                                        disabled={videosData?.eventPricing === "free" ? true : false}
                                                                        onClick={() => setIsModalOpen(true)}
                                                                        className="btn_red">Buy Tickets Now</button>
                                        }
                                    </div>
                                    <div className="ticket-social">
                                        <div className="social">
                                            <ion-icon name="logo-facebook"></ion-icon>
                                            <ion-icon name="logo-twitter"></ion-icon>
                                            <ion-icon name="logo-instagram"></ion-icon>
                                            <ion-icon name="logo-youtube"></ion-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title={null} open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
                    {
                        modalContentToShowFirst === "form" ?
                            <form onSubmit={handleSubmit(() => console.log('hey'))} autoComplete="false">
                                <h3>Please let us know who you are</h3>
                                <div className="form-group">
                                    {/* <Radio.Group
                                    options={plainOptions}
                                    onChange={onChangeDonationAmount}
                                    value={donationOptionToDisplay}
                                    optionType="button"
                                    buttonStyle="solid"
                                /> */}
                                </div>
                                <div className="flex_form">
                                    <div className="form-group space">
                                        <label htmlFor="firstName">First name</label>
                                        <Controller name="firstName" control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Input style={{ height: '5rem' }}
                                                        type="text"
                                                        {...field}
                                                        name="firstName" />
                                                )
                                            }} />
                                        {errors.firstName && <p className="errorMessage">{errors.firstName.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="lastName">Last name</label>
                                        <Controller name="lastName" control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Input style={{ height: '5rem' }}
                                                        type="text"
                                                        {...field}
                                                        name="lastName" />
                                                )
                                            }} />
                                        {errors.lastName && <p className="errorMessage">{errors.lastName.message}</p>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="emailAddress">Email address</label>
                                    <Controller name="emailAddress" control={control}
                                        render={({ field }) => {
                                            return (
                                                <Input style={{ height: '5rem' }}
                                                    type="email"
                                                    {...field}
                                                    name="emailAddress" />
                                            )
                                        }} />
                                    {errors.emailAddress && <p className="errorMessage">{errors.emailAddress.message}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tel">Phone number</label>
                                    <Controller name="tel" control={control}
                                        render={({ field }) => {
                                            return (
                                                <Input type="tel" style={{ height: '5rem' }}
                                                    {...field}
                                                    name="tel" />
                                            )
                                        }} />
                                    {errors.tel && <p className="errorMessage">{errors.tel.message}</p>}
                                </div>
                                <div style={{ display: 'block', marginTop: '15px' }}>

                                </div>
                                {/* {
                                            loadingData
                                                ?
                                                <button className="btn_red">
                                                    <span style={{ marginRight: '10px' }}>Creating Account. Please wait...</span>
                                                    <Spin indicator={antIcon} /></button>
                                                :
                                                <button className="btn_red">Donate Now</button>
                                        } */}
                                {/* <PaystackButton className="btn_red" {...componentProps} /> */}
                                <CheckMeFirst />
                                {/* <Divider orientation="left">Or</Divider>
                                <Link to="/signin"
                                    className="margin-bottom">Have an account already? Sign in here</Link> */}
                            </form>
                            :
                            <div className="successfulDonation">
                                <img src={Payment} alt="payment" />
                                <h3>Ticket purchase successful.</h3>
                                <p>Congratulations! We have sent a copy of your event ticket to your email address. Please
                                    check to confirm and bring it to the venue of the event on the day of the event.</p>
                                <button className="btn_red"
                                    onClick={() => Navigate(0)}>Close</button>
                            </div>
                    }
                </Modal>
                <Footer margin={true} />
            </Spin>
        </div >
    )
}

export default EventTickets;