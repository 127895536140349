import "./utils.css";

import React from 'react';
import { Link } from 'react-router-dom';

import { Input } from 'antd';
import { Controller, useForm } from "react-hook-form";

import AppRoute from './routes';

const Footer = props => {
    const { handleSubmit, control } = useForm({});
    return (
        <div className={`footer ${props.margin ? 'margin' : ''}`}>
            <div className="contain">
                <div className="biased_grid_4">
                    <div>
                        <h3>Lagos Theatre Igando</h3>
                        <p>The Lagos Theatre, Igando is one of four theatres set up by the Lagos State government - Ministry
                            of Tourism, Arts and Culture. It is managed by Corporate Dance World and Entertainment.</p>
                    </div>
                    <div className="inline-footer-div">
                        <div>
                            <h5>About Us</h5>
                            <ul>
                                <li>
                                    <Link to={AppRoute.events}>Events</Link>
                                </li>
                                <li>
                                    <Link to={AppRoute.about}>About Us</Link>
                                </li>
                                <li>
                                    <Link to={AppRoute.team}>Our Team</Link>
                                </li>
                                <li>
                                    <Link to={AppRoute.trainings}>Trainings</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h5>Resources</h5>
                            <ul>
                                <li>
                                    <Link to={AppRoute.visit}>How to get here</Link>
                                </li>
                                <li>
                                    <Link to={AppRoute.donate}>Donate</Link>
                                </li>
                                <li>
                                    <Link to={AppRoute.shop}>Art Gallery</Link>
                                </li>
                                <li>
                                    <Link to={AppRoute.newsroom}>NewsRoom</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h5>Reach out to us</h5>
                        <div>
                            <a target="_blank" href="https://www.instagram.com/lagostheatreigando/">
                                <ion-icon name="logo-instagram"></ion-icon>
                            </a>
                            <a target="_blank" href="http://lagostheatreigando">
                                <ion-icon name="logo-facebook"></ion-icon>
                            </a>
                            <a target="_blank" href="https://youtube.com/channel/UCTOiDaf0qLeq9wlqVDeCimg">
                                <ion-icon name="logo-youtube"></ion-icon>
                            </a>
                        </div>
                        <p style={{ width: '100%' }}>
                            <a href="tel:+2349161205677">+234 916 120 5677</a>, <a href="tel:+2348161585990">+234 816 158 5990</a></p>
                        <p>88, College Street, NYSC bus stop Igando, Lagos State.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;