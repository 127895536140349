const routes = {
    home: "/",
    tour: "/tour",
    ourteam: "/ourteam",

    // donate
    donate: "/donate",
    howToGive: "/how_to_give",
    plans: "/plans",

    // classes and trainings
    trainings: "/trainings",
    lti: "/lti",
    tafta: "/tafta",

    //shop
    howtoshop: "/howtoshop",
    shop: "/shop",

    team: "/team",
    rent: "/rent",
    about: "/about_us",
    director: "/director",
    generalInfo: "/general_info",
    visit: "/visit",
    people: "/people",
    events: "/events",
    tickets: "/tickets",
    explore: "/explore",
    facilities: "/facilities",
    detail: "/detail",
    signin: "/signin",
    signup: "/signup",
    forgotPassword: "/forgotpassword",
    privacy: "/privacy",
    terms: "/terms",
    contact: "/contact",
    newsroom: "/newsroom",
}

export default routes;