import "../mini.css";

import React from "react";

import Footer from "../../../utils/footer";
import AppRoutes from "../../../utils/routes";

import { Link } from "react-router-dom";

import Donate1 from "../../../assets/images/content/donate1.jpeg";
import Donate2 from "../../../assets/images/content/donate2.jpeg";
import Donate3 from "../../../assets/images/content/donate3.jpeg";
import Donate4 from "../../../assets/images/content/donate4.jpeg";

import SideNav from "../../../utils/sidenav";
import Nav from "../../../utils/nav";

const HowToDonate = () => {
    return (
        <div className="donate-page visit">
            <Nav />
            <div className="how_hero_1">

            </div>
            <div className="contain">
                <div>
                    <div className="grid_2_bias mt-5">
                        <div className="grid_block_right">
                            <div className="mini_story">
                                <h5 className="page_summary">These membership programs offer unique opportunities to become an integral part of the
                                    LTI community. Support us and enjoy exclusive benefits.</h5>
                                <div className="grid_2 donation">
                                    <div className="donateCategory">
                                        <div>
                                            <h4>Friends of the theatre</h4>
                                            <p>#500,000.00</p>
                                            <Link to={AppRoutes.plans}>See Donation Benefits <ion-icon name="arrow-forward-outline"></ion-icon></Link>
                                        </div>
                                    </div>
                                    <div className="donateCategory">
                                        <div>
                                            <h4>Lagos Theatre Igando Partners</h4>
                                            <p>#1,200,000.00</p>
                                            <Link to={AppRoutes.plans}>See Donation Benefits <ion-icon name="arrow-forward-outline"></ion-icon></Link>
                                        </div>
                                    </div>
                                    <div className="donateCategory">
                                        <div>
                                            <h4>Artistic Director’s Circle</h4>
                                            <p>#300,000.00</p>
                                            <Link to={AppRoutes.plans}>See Donation Benefits <ion-icon name="arrow-forward-outline"></ion-icon></Link>
                                        </div>
                                    </div>
                                    <div className="donateCategory">
                                        <div>
                                            <h4>Lagos Theatre Igando Virtual worldwide member</h4>
                                            <p>#100,000.00</p>
                                            <Link to={AppRoutes.plans}>See Donation Benefits <ion-icon name="arrow-forward-outline"></ion-icon></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3"></div>
                                <div>
                                    <div className="grid_2 images">
                                        <div>
                                            <img src={Donate1} alt="proposed building plan" />
                                        </div>
                                        <div>
                                            <img src={Donate2} alt="proposed building plan" />
                                        </div>
                                        <div>
                                            <img src={Donate3} alt="proposed building plan" />
                                        </div>
                                        <div>
                                            <img src={Donate4} alt="proposed building plan" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h5 className="page_summary">Sponsor A Recreational/Outdoor Area</h5>
                                    <p>
                                        Be a part of building our Recreational Area (Food Court, Art Gallery, Lounge,
                                        Café) and give your brand an opportunity to be seen by over a hundred (100)
                                        thousand people throughout the year by being a major sponsor for developing our
                                        recreational area.
                                    </p>

                                    <button className="btn_red">Donate to support our projects now</button>
                                </div>
                            </div>
                        </div>
                        <SideNav />
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default HowToDonate;