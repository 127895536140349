import "../mini.css";

import React, { useState, useEffect } from "react";
import NumberFormat from 'react-number-format';

import Footer from "../../../utils/footer";

import { Input, notification, Modal, Spin, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { usePaystackPayment } from 'react-paystack';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { v4 as uuid } from "uuid";
import Payment from "../../../assets/images/payment.svg";
import axiosCall from "../../../utils/axiosCall";
import Nav from "../../../utils/nav";

const DonationPlans = () => {

    const Navigate = useNavigate();
    const [uuidToken] = useState(uuid());
    const [loadingData, setLoadingData] = useState(false);
    const [donationValue, setDonationValue] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContentToShowFirst, setModalContentToShowFirst] = useState('form');
    const [userData, setUserData] = useState({});
    const [fetchingDonationPlans, setFetchingDonationPlans] = useState(false);
    const [donationsData, setDonationsData] = useState([]);
    const [currentDonation, setCurrentDonation] = useState('');

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const antIconLoader = <LoadingOutlined style={{ fontSize: 24, color: '#D43361' }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            description: message
        });
    };

    useEffect(() => {
        axiosCall.get('/donationplan/all')
            .then(donationData => {
                if (donationData.data.statusMessage === "success") {
                    setFetchingDonationPlans(false);
                    setDonationsData(donationData.data.message);
                } else {
                    openNotificationWithIcon('error', donationData.data.summary);
                }
            })
            .catch(err => {
                openNotificationWithIcon('error', 'An error occurred while fetching event videos. Please reload page to try again')
            })
    }, [])

    const publicKey = "pk_test_6001cfe393365d476119a4e494f32bcb1290cfea";
    const [amount, setAmount] = useState(0); // Remember, set in kobo!
    const digitsOnly = (value) => /^\d+$/.test(value);
    const validator = yup.object().shape({
        firstName: yup.string().required('Please tell us who you are'),
        lastName: yup.string().required('Please tell us who you are'),
        emailAddress: yup.string().email('Email is not valid').required('Email field can not be empty'),
        phoneNumber: yup.string()
            .min(9, 'Please enter a valid phone number')
            .required('Phone number field is required')
            .test('Digits only', 'The field should have digits only', digitsOnly)
    })

    const { handleSubmit, control, formState: { errors }, watch } = useForm({
        defaultValue: {
            emailAddress: "",
            password: "",
        },
        resolver: yupResolver(validator)
    });

    useEffect(() => {
        setUserData({
            emailAddress: watch('emailAddress')
        })
    }, [watch('emailAddress')])

    const handleFormDataChange = e => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }

    const config = {
        public_key: process.env.REACT_APP_FLUTTERWAVE_KEY,
        tx_ref: uuidToken,
        amount: +donationValue,
        currency: 'NGN',
        payment_options: 'card',
        customer: {
            email: userData.emailAddress,
        },
        customizations: {
            title: 'Lagos Theatre Igando',
            description: ''
        },
    };
    const handleFlutterPayment = useFlutterwave(config);
    const saveUserDonationToDB = e => {
        setIsModalOpen(false);
        let { firstName, lastName, emailAddress, phoneNumber } = e;
        axiosCall.post('/save-user-donations', {
            firstName, lastName, emailAddress, phoneNumber, donationId: currentDonation
        })
            .then(data => {
                if (data.data.statusMessage === 'success') {
                    setIsModalOpen(true);
                    setModalContentToShowFirst('success');
                    setLoadingData(false);
                } else {
                    setLoadingData(false);
                    openNotificationWithIcon('error', 'An error occurred while saving your transaction. Please let us know in case your account is charged.');
                }
            })
            .catch(err => {
                setLoadingData(false);
                openNotificationWithIcon('error', 'An error occurred while saving your transaction. Please let us know in case your account is charged.');
            })
    }

    const submitUserDonationData = e => {
        setUserData(e);
        setLoadingData(true);
        handleFlutterPayment({
            callback: async response => {
                if (response.status === "successful") {
                    saveUserDonationToDB(e);
                } else {
                    setLoadingData(false);
                    openNotificationWithIcon('error', 'Transaction cancelled');
                }
                closePaymentModal();
            },
            onClose: () => {
                setLoadingData(false);
                openNotificationWithIcon('error', 'Transaction cancelled')
            }
        });
    }

    let skeleton = [];
    for (let i = 0; i < 4; i++) {
        skeleton.push(<Skeleton active />)
    }

    return (
        <div className="trainings donation-plans visit">
            <Spin spinning={loadingData} indicator={antIconLoader}>
                <Nav />
                <div className="plans_hero_1">

                </div>
                <div className="contain">
                    <div>
                        <div className="donation_plans mt-5">
                            <div className="grid_block_right">
                                <div className="first_display plan_box">
                                    <h5 className="page_summary">These membership programs offer unique opportunities to become an integral part of the
                                        Lagos Theatre community. Support us and enjoy exclusive benefits.</h5>
                                    <div>
                                        <div>
                                            {
                                                !fetchingDonationPlans ?
                                                    <div className="grid_4">
                                                        {
                                                            donationsData.length ?
                                                                donationsData.map((donationPlans, index) => (
                                                                    <div className="plans_list" key={index}>
                                                                        <div className="plan_name">
                                                                            <h4>{donationPlans.name}</h4>
                                                                            <p>{<span><span>NGN</span><NumberFormat value={(+donationPlans.amount).toFixed(2)} className="foo"
                                                                                displayType={'text'} thousandSeparator={true} /></span>}</p>
                                                                        </div>
                                                                        <div className="advantage_list">
                                                                            <ul>
                                                                                {donationPlans.donationPlanBenefits.map((donationBenefits, index) => {
                                                                                    return (
                                                                                        <li key={index}><ion-icon name="checkmark-outline"></ion-icon>{donationBenefits.benefit}</li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                        <button onClick={() => {
                                                                            setCurrentDonation(donationPlans.id)
                                                                            setDonationValue(+donationPlans.amount)
                                                                            setIsModalOpen(true)
                                                                        }} className="btn_red">Donate now</button>
                                                                    </div>
                                                                ))
                                                                :
                                                                skeleton.map((placeHolder, index) => (
                                                                    <div className="item" key={index}>
                                                                        {placeHolder}
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                    :
                                                    <div className="grid_4">
                                                        {skeleton.map((placeHolder, index) => (
                                                            <div className="item" key={index}>
                                                                {placeHolder}
                                                            </div>
                                                        ))}
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title={null} open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
                    {
                        modalContentToShowFirst === "form" ?
                            <form onSubmit={handleSubmit(submitUserDonationData)} autoComplete="false">
                                <div className="flex_form">
                                    <div className="form-group space">
                                        <label htmlFor="firstName">First name</label>
                                        <Controller name="firstName" control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Input style={{ height: '5rem' }}
                                                        onChange={(e) => handleFormDataChange(e)}
                                                        type="text"
                                                        {...field}
                                                        name="firstName" />
                                                )
                                            }} />
                                        {errors.firstName && <p className="errorMessage">{errors.firstName.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="lastName">Last name</label>
                                        <Controller name="lastName" control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Input style={{ height: '5rem' }}
                                                        onChange={(e) => handleFormDataChange(e)}
                                                        type="text"
                                                        {...field}
                                                        name="lastName" />
                                                )
                                            }} />
                                        {errors.lastName && <p className="errorMessage">{errors.lastName.message}</p>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="emailAddress">Email address</label>
                                    <Controller name="emailAddress" control={control}
                                        render={({ field }) => {
                                            return (
                                                <Input style={{ height: '5rem' }}
                                                    onChange={(e) => handleFormDataChange(e)}
                                                    type="email"
                                                    {...field}
                                                    name="emailAddress" />
                                            )
                                        }} />
                                    {errors.emailAddress && <p className="errorMessage">{errors.emailAddress.message}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phoneNumber">Phone number</label>
                                    <Controller name="phoneNumber" control={control}
                                        render={({ field }) => {
                                            return (
                                                <Input type="tel" style={{ height: '5rem' }}
                                                    {...field}
                                                    name="phoneNumber" />
                                            )
                                        }} />
                                    {errors.phoneNumber && <p className="errorMessage">{errors.phoneNumber.message}</p>}
                                </div>
                                <div style={{ display: 'block', marginTop: '15px' }}>

                                </div>
                                {
                                    loadingData
                                        ?
                                        <button className="btn_red">
                                            <span style={{ marginRight: '10px' }}>Setting up Donation. Please wait...</span>
                                            <Spin indicator={antIcon} /></button>
                                        :
                                        <button className="btn_red">Donate Now - {<span><span>NGN</span><NumberFormat value={(+donationValue).toFixed(2)} className="foo"
                                            displayType={'text'} thousandSeparator={true} /></span>}</button>
                                }
                            </form>
                            :
                            <div className="successfulDonation">
                                <img src={Payment} alt="payment" />
                                <h3>Thank you for your donation.</h3>
                                <p>Your gift today will provide vital support for our
                                    artistic and educational programs, both in person and online.</p>
                                <button className="btn_red"
                                    onClick={() => Navigate(0)}>Close</button>
                            </div>
                    }
                </Modal>
                <Footer margin={true} />
            </Spin>
        </div>
    )
}

export default DonationPlans;