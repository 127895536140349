import "../mini.css";

import React from "react";

import Nav from "../../../utils/nav";
import Footer from "../../../utils/footer";

import { Divider, Table } from "antd";

import HallShowcase from "../../../assets/images/content/hall_showcase.jpeg";

import Sit3 from "../../../assets/images/content/sit.jpeg";
import Hall from "../../../assets/images/content/hall.jpg";
import Hall1 from "../../../assets/images/content/hall1.png";
import Hall2 from "../../../assets/images/content/hall2.png";
import Hall3 from "../../../assets/images/content/hall3.jpeg";
import SideNav from "../../../utils/sidenav";

const Trainings = () => {
    const columns = [
        {
            title: 'Main Hall',
            dataIndex: 'name',
            // render: (text) => <a>{text}</a>,
        },
        {
            title: 'Conference Hall',
            className: 'column-money',
            dataIndex: 'conference',
            align: 'right',
        },
        {
            title: 'Meeting Room',
            dataIndex: 'meeting',
        },
        {
            title: 'Restaurant Area',
            dataIndex: 'restaurant',
        },
    ];

    const secColumns = [
        {
            title: 'Lighting',
            dataIndex: 'lighting',
        },
        {
            title: 'Screen',
            dataIndex: 'screen',
        },
        {
            title: 'Sound',
            dataIndex: 'sound',
        },
        {
            title: 'Live Music Set',
            dataIndex: 'live',
        }
    ]
    const data = [
        {
            key: '1',
            name: 'Access Theatre, Acs, green room and rest room.',
            conference: '',
            meeting: 'Size: 4.5m*3.0m',
            restaurant: 'Size',
        },
        {
            key: '2',
            name: 'Capacity: 405seats-Theatre style seating.',
            conference: 'Sqm: 13.5Sqm',
            meeting: 'Sqm: 13.5Sqm',
            restaurant: 'Sqm:  22.27Sqm',
        },
        {
            key: '3',
            name: 'Price A: N840,000',
            conference: 'price: 18,000/hr',
            meeting: 'Price: 12,000/hr',
            restaurant: 'Price: 180,000',
        },
        {
            key: '4',
            name: 'Hired period:07h00 to 20h00+(40,000 per additional hour',
            conference: '07h00 to 20h00',
            meeting: 'Hired period: 07h00 to 20h00',
            restaurant: 'Hired Period: 08h00 to 19h00 + 40,000  per additional hour',
        },
        {
            key: '5',
            name: 'Price B: N480,000',
            conference: '',
            meeting: '',
            restaurant: '',
        },
        {
            key: '6',
            name: 'Hired period: 07h00 to 13h00',
            conference: '',
            meeting: '',
            restaurant: '',
        },
    ];
    const secData = [
        {
            key: '1',
            lighting: 'N180,000 per event',
            screen: 'Screen: N120,000 per event',
            sound: 'Sound:  N120,000 per event',
            live: 'Live music set: N60,000 per event',
        },
    ]


    return (
        <div className="visit">
            <Nav />
            <div className="lti_hero_1">
                <video src="https://lagostheatrevideos.s3.amazonaws.com/visit.mp4" playsInline={true} autoPlay muted loop />
                <div className="black_overlay">
                </div>
            </div>
            <div className="contain mt-5">
                <div>
                    <div>
                        <div className="grid_2_bias mt-5">
                            <div className="grid_block_right">
                                <div className="mini_story">
                                    <div>
                                        <h5 className="desktop-only page_summary">Explore our Programs and Resources</h5>
                                        <div className="desktop-only video_cover">
                                            <div className="grid_3">
                                                <img src={Sit3} alt="sit3" className="rent_image" />
                                                <img src={HallShowcase} alt="sit3" className="rent_image" />
                                                <img src={Hall} alt="sit3" className="rent_image" />
                                            </div>
                                        </div>
                                        <div>
                                            <h5 className="page_summary">The Theatre Hall</h5>
                                            <p>
                                                The main hall is a 405 seater with AV equipment ( including lighting, screen, sound, and a
                                                live music set). It is suitable for auditions, events / shows and corporate meetings
                                            </p>
                                            <div className="grid_3">
                                                <img src={Hall1} alt="sit3" className="rent_image" />
                                                <img src={Hall2} alt="sit3" className="rent_image" />
                                                <img src={Hall3} alt="sit3" className="rent_image" />
                                            </div>
                                            <p>
                                                Other available spaces include two (2) conference rooms, a green room, a restaurant, a lobby / art
                                                gallery and parking spaces.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SideNav />
                        </div>
                        <Divider />
                        <div>
                            <div>
                                <h2 className="page_sec_header">Rental Information</h2>
                            </div>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                title={null}
                                footer={null}
                            />
                            <h2 className="page_sec_header">AV Equipments</h2>
                            <Table
                                columns={secColumns}
                                dataSource={secData}
                                bordered
                                title={null}
                                footer={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default Trainings;