import 'antd/dist/antd.css';
import './assets/css/index.css';

import React from "react";
import ReactDOM from "react-dom/client";

import AppRoutes from "./routes.js";

const App = () => {
    return (
        <AppRoutes />
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);