import "./event.css";

import React from "react";

import Footer from "../../../utils/footer";

import ChargeBee from "./img/chargebee_animation.svg";
import StarImg from "./img/star.svg";

import Hero1 from "./img/hero1.jpg";
import Hero2 from "./img/hero2.jpg";
import Hero3 from "./img/hero3.jpg";
import Hero4 from "./img/hero4.jpg";

import Judge1 from "./img/judge1.jpeg";
import Judge2 from "./img/judge2.jpeg";
import Judge3 from "./img/judge3.jpeg";
import Judge4 from "./img/judge4.jpeg";

const AGT = () => {
    return (
        <div className="agt-design">
            <div className="total-cover">
                <div className="hero">
                    <div className="contain">
                        <div className="grid_2">
                            <div className="white-overlay">
                                <div className="agt-first">
                                    <p>Are you ready for the biggest Talent Hunt of the year?</p>
                                    <p>If you know you're the best and ready to let your talent shine, then
                                        don't miss the...</p>
                                </div>
                                <h2>Alimosho Talent Hunt.</h2>
                                <div>
                                    <div>
                                        <p>This is an opportunity to showcase your talent on a world class
                                            stage and win cash prizes.</p>
                                        <p>This is an opportunity to give wings to your dreams and become the next
                                            big Talent from Lagos.</p>
                                        <p>An opportunity for all Lagos residents to exhibit their creativity and
                                            ideas and win cash prizes.</p>
                                        <p>Limited slots available, hurry now.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="agt-judges mt-5">
                    <div className="contain">
                        <h2>Meet the Judges</h2>
                        <div className="grid_">
                            <div>
                                <div className="grid_4">
                                    <div>
                                        <img src={Judge1} alt="chargebee website hero" />
                                        <h5>Ukalina Opuwari</h5>
                                        <p>Award-winning dancer, Fitness inflencer/coach, Winner Maltina Dance All
                                            Family Reality Tv show 2008.
                                        </p>
                                    </div>
                                    <div>
                                        <img src={Judge2} alt="chargebee website hero" />
                                        <h5>Malachi Anozie AKA King Mali</h5>
                                        <p>Award-winning dance artist/fitness coach, 1st Runner up - Dance with
                                            Peter 2015
                                        </p>
                                    </div>
                                    <div>
                                        <img src={Judge3} alt="chargebee website hero" />
                                        <h5>Oyiko</h5>
                                        <p>Renowned comedian from Alimosho
                                        </p>
                                    </div>
                                    <div>
                                        <img src={Judge4} alt="chargebee website hero" />
                                        <h5>Akpos</h5>
                                        <p>Renowned/internationally recognised comedian
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tickets-bar">
                    <div className="desktop-only">
                        <h2>Click here to learn more and register! <a
                            target="_blank"
                            href="https://selar.co/1785">Buy Your Tickets</a></h2>
                    </div>
                    <div className="mobile-only">
                        <h2><a
                            // style={{ color: '#fff' }}
                            target="_blank"
                            href="https://selar.co/1785">Click here to Buy Your Tickets</a></h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AGT;