import "../mini.css";

import React, { useState } from "react";

import Footer from "../../../utils/footer";

import ManSinging from "../../../assets/images/content/man_singing.jpg";

import About2 from "../../../assets/images/content/about4.jpg";
import About3 from "../../../assets/images/content/about5.jpg";
import About4 from "../../../assets/images/content/about6.jpg";
import About5 from "../../../assets/images/content/about5.png";
import About6 from "../../../assets/images/content/about6.png";
import About7 from "../../../assets/images/content/about7.png";
import Nav from "../../../utils/nav";

const AboutUs = () => {
    const [hideMe, setHideMe] = useState(false);
    return (
        <div className="visit">
            <Nav />
            <div className="about-hero-cover">
                <div className="about_sect">
                    <img src={ManSinging} alt="" />
                </div>
                <div className="sec-about-sect">
                </div>
            </div>
            <div className="contain">
                <div>
                    <div className="container">
                        <div className="mini_story">
                            <p>
                                <h1 className="inline-header">The Lagos Theatre, Igando </h1> is one of four theatres set up by the Lagos State
                                government - Ministry of Tourism, Arts and Culture. It is managed by Corporate Dance World and
                                Entertainment. Since its unveiling in 2019, the Lagos Theatre, Igando has been identified as a
                                centre for recreation, community development, job creation and promotion of the arts.
                                Since activities commenced in the Second Quarter of 2021, the Lagos Theatre Igando has hosted
                                several art-themed and entertainment events including exhibitions, plays, shows, community
                                celebrations, and activities for children.
                            </p>
                            <div>
                                {
                                    hideMe ?
                                        <div>
                                            <div className="grid_3"
                                                style={{ gridGap: 10 }}>
                                                <img src={About2} alt="girl smiling" />
                                                <img src={About3} alt="girl smiling" />
                                                <img src={About4} alt="girl smiling" />
                                            </div>
                                            <p>
                                                We are committed to collaborating with the people of Alimosho community and the Lagos State
                                                Government towards artistic talent discovery, promotion and presentation, subsequent community
                                                development as well as facilitating job creation for the creative youths. It is our belief that
                                                this platform is integral to youth development and the enabling and reinforcement of positive
                                                behaviours among children and youths of Alimosho.
                                            </p>
                                            <h3>Our achievements</h3>
                                            <p>We have engaged and entertained our customers via a Fitness Marathon Event, Children's Day
                                                Celebration including two stage plays, Eid El Fitri Event featuring two stage plays, Arts
                                                Exhibitions, a one-month Summer School Programme for Children where 20 children from 10
                                                families were each given a scholarship by the theatre. We also hold weekly activities which
                                                includes; Performing Arts Classes in Dance and Music, LTI Creative Club and Hall Rentals.</p>
                                            <div className="grid_3"
                                                style={{ gridGap: 10 }}>
                                                <img src={About5} alt="girl smiling" />
                                                <img src={About6} alt="girl smiling" />
                                                <img src={About7} alt="girl smiling" />
                                            </div>
                                            <p>Our Facilities includes a Theatre of over four hundred (400) seats, two (2) conference
                                                rooms, a green room, a restaurant, a lobby/art gallery, AV equipment
                                                (including, lighting, screen, sound, and a live music set). </p>
                                        </div>
                                        :
                                        ''
                                }
                            </div>
                            <button
                                onClick={() => { setHideMe(!hideMe) }}
                                className="btn_red">
                                {!hideMe ? 'Learn More' : 'Hide'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default AboutUs;