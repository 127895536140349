import React, { useEffect, useState } from "react";
import { Divider, notification, Skeleton, Modal } from "antd";
import axiosCall from "../../../utils/axiosCall";
import ReactPlayer from 'react-player';
import CancelImg from '../../../assets/images/x-white.svg';

import Footer from "../../../utils/footer";

import Hero from "../../../assets/images/content/explore_hero.jpg";
import Nav from "../../../utils/nav";

const Homepage = () => {
    const [fetchingVideosData, setFetchingVideosData] = useState(false);
    const [videosData, setVideosData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const playerRef = React.useRef(null);
    const [videoToPlayLink, setVideoToPlayLink] = useState('https://lagostheatrevideos.s3.amazonaws.com/summer+intensive+2021.MP4');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setPlayVideo(false);
        setIsModalOpen(false);
    };

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            description: message
        });
    };
    useEffect(() => {
        axiosCall.get('/events-videos')
            .then(videoData => {
                if (videoData.data.statusMessage === "success") {
                    setFetchingVideosData(false);
                    setVideosData(videoData.data.message);
                } else {
                    openNotificationWithIcon('error', videoData.data.summary);
                }
            })
            .catch(err => {
                openNotificationWithIcon('error', 'An error occurred while fetching event videos. Please reload page to try again')
            })
    }, [])
    let skeleton = [];
    for (let i = 0; i < 8; i++) {
        skeleton.push(<Skeleton active />)
    }

    const openVideoModal = e => {
        setVideoToPlayLink(e);
        setIsModalOpen(true);
        // handlePlayerReady();
    }

    const pauseMediaPlayer = async () => {
        await setPlayVideo(false);
        setIsModalOpen(false);
    }

    return (
        <div className="homepage">
            <Nav />
            <div className="hero_section">
                <video
                    poster={Hero} playsInline={true}
                    src="https://lagostheatrevideos.s3.amazonaws.com/dance.mp4" autoPlay muted loop />
                <div className="black_overlay">

                </div>
                <div className="hero_section_div">
                    <h3 className="mb-4">The Lagos State Theatre Igando Youtube Channel</h3>
                    <a
                        className="mt-5"
                        href="https://youtube.com/channel/UCTOiDaf0qLeq9wlqVDeCimg"
                        target="_blank"
                    >Check us Out <ion-icon name="arrow-forward-outline"></ion-icon></a>
                </div>
            </div>
            <div className="events_tab mt-4 pb-5">
                <div className="contain">
                    <Divider style={{ marginTop: 0 }} />
                    <div>
                        {
                            !fetchingVideosData ?
                                <div className="grid_4">
                                    {
                                        videosData.length ?
                                            videosData.map((videos, index) => (
                                                <div
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => openVideoModal(videos.videoLink)}
                                                    className="homepage_event_cover explore_list" key={index}>
                                                    <div className="homepage_event_header">
                                                        <img src={videos.poster} alt="_1" />
                                                        <div className="videoIcon">
                                                            <ion-icon name="play-circle-outline"></ion-icon>
                                                        </div>
                                                    </div>
                                                    <div className="homepage_event_body">
                                                        <h2 className="event_title">{videos.name}</h2>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            skeleton.map((placeHolder, index) => (
                                                <div className="item" key={index}>
                                                    {placeHolder}
                                                </div>
                                            ))
                                    }
                                </div>
                                :
                                <div className="grid_4">
                                    {skeleton.map((placeHolder, index) => (
                                        <div className="item" key={index}>
                                            {placeHolder}
                                        </div>
                                    ))}
                                </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
            <Modal title={null} className="videoPlayer marathon-media-player"
                width={800}
                footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <ReactPlayer width="100%" height="100%" url={videoToPlayLink}
                    playing={playVideo} style={{ background: 'black' }} playsinline={true} controls={false} />
                <div className="stopVideoButton">
                    <button onClick={() => {
                        pauseMediaPlayer()
                    }}><img src={CancelImg} alt="cancel modal" /></button>
                </div>
            </Modal>
        </div>
    )
}

export default Homepage;