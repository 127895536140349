import React from "react";
import { Divider } from "antd";

import Footer from "../../utils/footer";

import Unstoppable from "../../assets/images/shop/unstoppable.jpg";
import Apocalypse from "../../assets/images/shop/apocalypse.jpg";
import Wakanda from "../../assets/images/shop/wakanda.jpg";
import Africa from "../../assets/images/shop/africa.jpg";
import Alkebulan from "../../assets/images/shop/alkebulan.jpg";
import Worry from "../../assets/images/shop/worry.jpg";
import Afro from "../../assets/images/shop/afro.jpg";
import Drummers from "../../assets/images/shop/drummers.jpg";
import Devastated from "../../assets/images/shop/devastated.jpg";
import Summer from "../../assets/images/shop/summer.jpg";
import Nav from "../../utils/nav";

const Homepage = () => {
    return (
        <div className="shop">
            <Nav />
            <div className="shop_hero_section">
                <h3>Arts Gallery</h3>
            </div>
            <div className="events_tab mt-4">
                <div className="contain">
                    <Divider style={{ marginTop: 0 }} />
                    <div>
                        <div className="shop grid_5">
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Unstoppable} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">The Unstoppable</h2>
                                    <p className="event_date">John Abodunrin</p>
                                    <div className="shop-pheripheral">
                                        <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 24" x 24"</p>
                                        <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    </div>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Apocalypse} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Apocalypse or Revolution</h2>
                                    <p className="event_date">Nathan Fomme</p>
                                    <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 16" x 20"</p>
                                    <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Wakanda} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Oh Wakanda</h2>
                                    <p className="event_date">Nathan Fomme</p>
                                    <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 20" x 24"</p>
                                    <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Africa} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Africa's Pride</h2>
                                    <p className="event_date">Isaac Udoh</p>
                                    <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 16" x 24"</p>
                                    <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Alkebulan} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Call her name: Alkebulan</h2>
                                    <p className="event_date">Nathan Fomme</p>
                                    <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 20" x 24"</p>
                                    <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Worry} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Don't Worry</h2>
                                    <p className="event_date">Nathan Fomme</p>
                                    <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 24" x 36"</p>
                                    <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Afro} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">You look good in your Afro</h2>
                                    <p className="event_date">Ponmile Olayade</p>
                                    <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 24" x 36"</p>
                                    <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Drummers} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Obitun Drummers</h2>
                                    <p className="event_date">Isah Musa Alli</p>
                                    <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 36" x 48"</p>
                                    <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Devastated} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Devastated</h2>
                                    <p className="event_date">Kingsley Omogidono</p>
                                    <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 24" x 24"</p>
                                    <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_shop_image">
                                    <img src={Summer} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Summer Feeling</h2>
                                    <p className="event_date">Isah Musa Alli</p>
                                    <p className="event_date"><ion-icon name="expand-outline"></ion-icon>: 36" x 48"</p>
                                    <p className="event_date"><ion-icon name="calendar-outline"></ion-icon>: 2022</p>
                                    <p className="event_date"><ion-icon name="call-outline"></ion-icon>: <a href="tel:+2348062146388">+2348062146388</a> , <a href="tel:+2348062146388">+2348062146388</a></p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default Homepage;