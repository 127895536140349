import "./mini.css";

import React, { useState } from "react";

import Footer from "../../utils/footer";

import Alimosho from "../../assets/images/newsletter/alimosho.jpg";
import Teens from "../../assets/images/newsletter/teens.jpeg";
import Film from "../../assets/images/newsletter/film.jpeg";
import Gathering from "../../assets/images/newsletter/gathering.jpeg";
import Children from "../../assets/images/newsletter/children.JPG";
import SchoolVisit from "../../assets/images/newsletter/schoolvisit.jpeg";
import Nav from "../../utils/nav";

const Trainings = () => {
    const [hideMe, setHideMe] = useState(false);
    return (
        <div className="newsletter visit">
            <Nav />
            <div className="newsletter_hero_1">
                <h3>Newsletter</h3>
            </div>
            <div className="contain mt-5">
                <div>
                    <div className="grid_4">
                        <div className="homepage_block_grid">
                            <div className="homepage_grid_div">
                                <img src={Alimosho} alt="" />
                                <div className="homepage_grid_text">
                                    <h3 className="homepage_grid_title">Lagos Theatre partners with
                                        AlimoshoToday, throws in full support for Alimosho students event
                                    </h3>
                                    <p className="homepage_grid_mini">
                                        The Lagos Theatre, with a 400 capacity and equipped with state-of-the-art
                                        facilities, now stands as the venue of the Tomorrow Leaders Conference which will
                                        take place on September 23, 2022.
                                    </p>
                                </div>
                                <div className="homepage_grid_footer">
                                    <a href="https://www.alimoshotoday.com/alimosho-news/lagos-theatre-partners-with-alimoshotoday-throws-in-full-support-for-alimosho-students-event-5170134"
                                        rel="noreferrer" target="_blank">
                                        Check out Story <ion-icon name="arrow-forward-outline"></ion-icon></a>
                                </div>
                            </div>
                        </div>
                        <div className="homepage_block_grid">
                            <div className="homepage_grid_div">
                                <img src={Teens} alt="" />
                                <div className="homepage_grid_text">
                                    <h3 className="homepage_grid_title">Lagos inaugurates club for teens, children</h3>
                                    <p className="homepage_grid_mini">
                                        The Lagos Theatre Igando has inaugurated a creative club for residents of
                                        Igando, Alimosho and environs to nurture creative talents among teenagers and
                                        children in the areas.
                                    </p>
                                </div>
                                <div className="homepage_grid_footer">
                                    <a href="https://punchng.com/lagos-inaugurates-club-for-teens-children/"
                                        rel="noreferrer" target="_blank">
                                        Check out Story <ion-icon name="arrow-forward-outline"></ion-icon></a>
                                </div>
                            </div>
                        </div>
                        <div className="homepage_block_grid">
                            <div className="homepage_grid_div">
                                <img src={Film} alt="" />
                                <div className="homepage_grid_text">
                                    <h3 className="homepage_grid_title">Lagos State/ Africa Film Academy
                                        Film in a Box Training
                                    </h3>
                                    <p className="homepage_grid_mini">
                                        The Africa Film Academy with support from the Lagos state government is conducting a
                                        6-week intense training on Acting, Art Directing, Directing, Light,
                                        Scriptwriting, Creative Design For Film, Make Up For Film, Post-production,
                                        Production, Sound production.
                                    </p>
                                </div>
                                <div className="homepage_grid_footer">
                                    <a href="Https://www.africafilmacademy.com/film-in-a-box-lagos"
                                        rel="noreferrer" target="_blank">
                                        Check out Story <ion-icon name="arrow-forward-outline"></ion-icon></a>
                                </div>
                            </div>
                        </div>
                        <div className="homepage_block_grid">
                            <div className="homepage_grid_div">
                                <img src={Gathering} alt="" />
                                <div className="homepage_grid_text">
                                    <h3 className="homepage_grid_title">It’s few days to D’gathering season and you
                                        shouldn’t be left behind!
                                    </h3>
                                    <p className="homepage_grid_mini">
                                        ⁣⁣⁣D’gathering is an event aimed at discovering, celebrating, and connecting young
                                        people of passion and creativity. It has a major feature of an ART EXHIBITION by
                                        renowned Artists with unique styles, medium and message across the state of the
                                        federation.
                                    </p>
                                </div>
                                <div className="homepage_grid_footer">
                                    <a href="https://www.instagram.com/p/CVRxvH8qyWm/?igshid=YmMyMTA2M2Y="
                                        rel="noreferrer" target="_blank">
                                        Check out Story <ion-icon name="arrow-forward-outline"></ion-icon></a>
                                </div>
                            </div>
                        </div>
                        <div className="homepage_block_grid">
                            <div className="homepage_grid_div">
                                <img src={SchoolVisit} alt="" />
                                <div className="homepage_grid_text">
                                    <h3 className="homepage_grid_title">Lagos govt trains students in creative
                                        skills. on Punch News.
                                    </h3>
                                    <p className="homepage_grid_mini">
                                        ⁣⁣⁣The Lagos State Theatre, Igando, said students’ practical exposure
                                        to creative skills would nurture their talent and give them
                                        the edge to fit into any creative sector.
                                    </p>
                                </div>
                                <div className="homepage_grid_footer">
                                    <a href="https://punchng.com/lagos-govt-trains-students-in-creative-skills/"
                                        rel="noreferrer" target="_blank">
                                        Check out Story <ion-icon name="arrow-forward-outline"></ion-icon></a>
                                </div>
                            </div>
                        </div>
                        <div className="homepage_block_grid">
                            <div className="homepage_grid_div">
                                <img src={Children} alt="" />
                                <div className="homepage_grid_text">
                                    <h3 className="homepage_grid_title">Fun, Theatre, Dance at the Children’s Day
                                        event
                                    </h3>
                                    <p className="homepage_grid_mini">
                                        We @Lagostheatreigando care about the children, and what better way to
                                        show it to guarantee maximum fun on their special day.
                                    </p>
                                </div>
                                <div className="homepage_grid_footer">
                                    <a href="https://www.instagram.com/tv/CQNhMOshli2/?igshid=YmMyMTA2M2Y%3D"
                                        rel="noreferrer" target="_blank">
                                        Check out Story <ion-icon name="arrow-forward-outline"></ion-icon></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default Trainings;