import "./event.css";

import React, { useRef, useEffect } from "react";

import { Tabs } from "antd";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Footer from "../../../utils/footer";

import ChargeBee from "./img/chargebee_animation.svg";
import StarImg from "./img/star.svg";

import Hero1 from "./img/agt2.jpg";
import Hero2 from "./img/hero5.jpg";
import Hero3 from "./img/hero6.jpg";

import Judge1 from "./img/judge1.jpeg";
import Judge2 from "./img/judge2.jpeg";
import Judge3 from "./img/judge3.jpeg";
import Judge4 from "./img/judge4.jpeg";

const AGT = () => {
    const { TabPane } = Tabs;
    // gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);
    // useEffect(() => {
    //     const element = ref.current;
    //     gsap.fromTo(
    //         element.querySelector(".first-edit"),
    //         {
    //             opacity: 0.4,
    //             y: -5
    //         },
    //         {
    //             opacity: 1,
    //             y: 0,
    //             scrollTrigger: {
    //                 trigger: element.querySelector(".first"),
    //                 start: "top top",
    //                 end: "bottom center",
    //                 scrub: true
    //             }
    //         }
    //     );
    // }, []);

    // useEffect(() => {
    //     const element = ref.current;
    //     gsap.fromTo(
    //         element.querySelector(".second-edit"),
    //         {
    //             opacity: 0,
    //             scale: 0.2,
    //             y: -20
    //         },
    //         {
    //             opacity: 1,
    //             y: 0,
    //             scale: 1,
    //             duration: 1,
    //             ease: "none",
    //             scrollTrigger: {
    //                 trigger: element.querySelector(".second"),
    //                 start: "top center",
    //                 end: "bottom top",
    //                 scrub: true
    //             }
    //         }
    //     );
    // }, []);

    // useEffect(() => {
    //     const element = ref.current;
    //     gsap.from(element.querySelector(".third-edit"), {
    //         scale: 0,
    //         ease: "none",
    //         scrollTrigger: {
    //             trigger: element.querySelector(".third"),
    //             scrub: true,
    //             start: "top bottom",
    //             end: "top top"
    //         }
    //     });
    // }, []);
    return (
        <div ref={ref} className="agt-design full-sales-copy">
            <div className="total-cover">
                <div className="agt-sales">
                    <div>
                        <div className="first-bar">
                            {/* <h2>It's Time To let your Talent shine.</h2> */}
                            <p> &bull; Are you a talented youth of Alimosho community?</p>
                            <p> &bull; Do you know a resident of Alimosho looking for an opportunity to showcase their talent?</p>
                            <p> &bull; If yes, Here's some good news for you.</p>
                        </div>
                    </div>
                    <div>
                        <h1>Alimosho Talent Hunt 2022.</h1>
                        <img src={Hero1} alt="Hero1" className="hero" />
                        <h4>"Where WORDS fail to describe, Talent speaks."</h4>
                        <p>
                            Lagos Theatre Alimosho will be hosting a Talent hunt for Lagos residents and will allow the
                            talented youths of Lagos to showcase their talent on a world class stage and win cash prizes.
                        </p>
                        <p>
                            Whatever talent you think you have, here's your chance to showcase it to the world.
                        </p>
                        <p>
                            We are providing a platform for all Lagos residents to exhibit their creativity and
                            ideas and be rewarded for it.
                        </p>
                        <h6>Don't miss out!</h6>
                    </div>
                    <div className="extra-story first">
                        <div className="first-edit grid_2">
                            <div>
                                <h3 className="mt-5">👉 Talent Categories.</h3>
                                <img src={Hero2} alt="Hero1" className="inline-hero" />
                                <div>
                                    <ul>
                                        <li>Singing/Music</li>
                                        <li>Acting</li>
                                        <li>Dancing</li>
                                        <li>Visual art and painting</li>
                                    </ul>
                                </div>
                                <p>
                                    No matter how big or small you think your talents are, Lagos Theatre Alimosho is
                                    allowing you to let them shine.
                                </p>
                                <p>
                                    The Talent hunt is open to all Lagos residents.
                                </p>
                                <p>The only requirement to participate is a registration fee of N2000.</p>
                                <a
                                    target="_blank"
                                    href="https://selar.co/1785">Click Here To Register</a>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                    <div className="extra-story second">
                        <div className="second-edit grid_2">
                            <div>

                            </div>
                            <div>
                                <h3 className="mt-5">👉 Rules & Guidelines</h3>
                                <img src={Hero3} alt="Hero1" className="inline-hero" />
                                <p>
                                    Registration for the talent hunt will close on the 10th of December 2022, and the
                                    talent hunt phase begins.
                                </p>
                                <Tabs>
                                    <TabPane key="1" tab="Phase 1">
                                        <div>
                                            <p>
                                                When registration closes, those that registered will come for a general selection/ audition
                                                on the 7th of December.
                                            </p>
                                            <p>Those who bring in their best act will move to the semi-finals stage on the 20th of December.</p>
                                        </div>
                                    </TabPane>
                                    <TabPane key="2" tab="Phase 2 (Semi-final stage)">
                                        <div>
                                            <p>Another selection will be made on the 20th of December, and 10 people will be picked.</p>
                                            <p>The final 10 contestants will proceed to the final, which will be on the 23rd of December.</p>
                                        </div>
                                    </TabPane>
                                    <TabPane key="3" tab="Phase 3 (Final Stage)">
                                        <div>
                                            <p>The winners will be picked among the final 10 contestants on the 23rd of December, which
                                                will also be the final stage of the competition.</p>
                                        </div>
                                    </TabPane>
                                </Tabs>
                                <div>
                                    <h5>Note: To be among the top 3 winners, you must bring in your best acts.</h5>
                                    <a
                                        target="_blank"
                                        href="https://selar.co/1785">Click Here To Become The Next Big Talent From Alimosho.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="third">
                        <div className="third-edit">
                            <h3 className="mt-5">👉 Rewards & Prizes.</h3>

                            <p>
                                A sum of N500,000 will be shared among the winners.
                            </p>
                            {/* <p>
                                The winner will get a sum of 250K
                            </p>
                            <p>
                                1st runner up will get 150k
                            </p>
                            <p>
                                2nd runner-up will get 100k.
                            </p> */}
                        </div>
                    </div>
                    <div>
                        <h3 className="mt-5">👉 Meet the Judges</h3>
                        <div>
                            <div className="grid_4">
                                <div>
                                    <img src={Judge1} alt="chargebee website hero" />
                                    <h5>Ukalina Opuwari</h5>
                                    <p>Award-winning dancer, Fitness inflencer/coach, Winner Maltina Dance All
                                        Family Reality Tv show 2008.
                                    </p>
                                </div>
                                <div>
                                    <img src={Judge2} alt="chargebee website hero" />
                                    <h5>Malachi Anozie AKA King Mali</h5>
                                    <p>Award-winning dance artist/fitness coach, 1st Runner up - Dance with
                                        Peter 2015
                                    </p>
                                </div>
                                <div>
                                    <img src={Judge3} alt="chargebee website hero" />
                                    <h5>Oyiko</h5>
                                    <p>Renowned comedian from Alimosho
                                    </p>
                                </div>
                                <div>
                                    <img src={Judge4} alt="chargebee website hero" />
                                    <h5>Akpos</h5>
                                    <p>Renowned / internationally recognised comedian
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 className="mt-5">👉 About Lagos Theater Igando</h3>
                        <p>Lagos Theatre Igando is a community hub for Arts, culture, and
                            entertainment located in Igando, Lagos.</p>
                        <p>We are an Art venue where kids' and adults' creativity is being
                            Nurtured, and we have a reputation for consistently promoting talents and arts.</p>
                        <p>We're working in line with the mission of the Lagos state government to develop
                            talented persons, nurture raw talents and inculcate the culture of appreciating
                            theatrical works into society by engaging children and adults alike.</p>
                        <p>That's why we are allowing Igando residents to showcase their talent to the world.</p>
                    </div>
                </div>
                <div className="tickets-bar">
                    <div className="desktop-only">
                        <h2>Click here to learn more and register! <a
                            target="_blank"
                            href="https://selar.co/1785">Buy Your Tickets</a></h2>
                    </div>
                    <div className="mobile-only">
                        <h2><a
                            target="_blank"
                            href="https://selar.co/1785">Click here to Buy Your Tickets</a></h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AGT;