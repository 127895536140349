import "./event.css";

import React, { useRef, useEffect } from "react";

import { Tabs, Table, Divider } from "antd";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Footer from "../../../utils/footer";

import Colors from "../../../assets/images/content/colors.JPG";

import ChargeBee from "./img/chargebee_animation.svg";
import StarImg from "./img/star.svg";

import Hero1 from "./img/agt2.jpg";
import Hero2 from "./img/hero5.jpg";
import Hero3 from "./img/hero6.jpg";
import Hero4 from "./img/hero4.jpg";

import Event1 from "./img/event_1.jpg";

import Day2 from "./img/day2.JPG";
import Day4 from "./img/day4.jpg";
import Day5 from "./img/day5.jpg";

import Judge1 from "./img/judge1.jpeg";
import Judge2 from "./img/judge2.jpeg";
import Judge3 from "./img/judge3.jpeg";
import Judge4 from "./img/judge4.jpeg";

const _5DayEventCalender = () => {
    const { TabPane } = Tabs;
    // gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);
    // useEffect(() => {
    //     const element = ref.current;
    //     gsap.fromTo(
    //         element.querySelector(".first-edit"),
    //         {
    //             opacity: 0.4,
    //             y: -5
    //         },
    //         {
    //             opacity: 1,
    //             y: 0,
    //             scrollTrigger: {
    //                 trigger: element.querySelector(".first"),
    //                 start: "top top",
    //                 end: "bottom center",
    //                 scrub: true
    //             }
    //         }
    //     );
    // }, []);

    // useEffect(() => {
    //     const element = ref.current;
    //     gsap.fromTo(
    //         element.querySelector(".second-edit"),
    //         {
    //             opacity: 0,
    //             scale: 0.2,
    //             y: -20
    //         },
    //         {
    //             opacity: 1,
    //             y: 0,
    //             scale: 1,
    //             duration: 1,
    //             ease: "none",
    //             scrollTrigger: {
    //                 trigger: element.querySelector(".second"),
    //                 start: "top center",
    //                 end: "bottom top",
    //                 scrub: true
    //             }
    //         }
    //     );
    // }, []);

    // useEffect(() => {
    //     const element = ref.current;
    //     gsap.from(element.querySelector(".third-edit"), {
    //         scale: 0,
    //         ease: "none",
    //         scrollTrigger: {
    //             trigger: element.querySelector(".third"),
    //             scrub: true,
    //             start: "top bottom",
    //             end: "top top"
    //         }
    //     });
    // }, []);


    // Event Calender Columns
    const calenderColumns = [
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
        },
        {
            title: 'Tickets',
            dataIndex: 'tickets',
            key: 'tickets',
        },
    ];

    // Event Data

    // day 1
    const day1DataSource = [
        {
            key: '1',
            event: <p>Alimosho Talent Fiesta</p>,
            details: <div>
                <p>Date: Tues, 20th Dec, 2022</p>
                <p>Time: 10am</p>
            </div>,
            tickets: <p>FREE</p>,
        },
        {
            key: '2',
            event: <p>A Stage Play by Crown Troupe of Africa</p>,
            details: <div>
                <p>Time: 4pm</p>
            </div>,
            tickets: <div>
                <p>Ticket: N2,000</p>
            </div>
        },
    ];
    // day 2
    const day2DataSource = [
        {
            key: '1',
            event: <p>Aloyinlapa and Crew and A Stage Play By ANTP Alimosho</p>,
            details: <div>
                <p>Date: Wed, 21st Dec, 2022</p>
                <p>Time: 4pm</p>
            </div>,
            tickets: <div>
                <p>Regular: N3,000</p>
                <p>VIP: N5,000</p>
            </div>,
        }
    ];
    // day 3
    const day3DataSource = [
        {
            key: '1',
            event: <p>A Choral Performance by Bellarome Chorale</p>,
            details: <div>
                <p>Date: Thurs, 22nd Dec, 2022</p>
                <p>Time: 4pm</p>
            </div>,
            tickets: <div>
                <p>Regular: 5,000</p>
                <p>VIP: N10,000</p>
            </div>,
        }
    ];
    // day 4
    const day4DataSource = [
        {
            key: '1',
            event: <p>Alimosho Talent Fiesta Grand Finale</p>,
            details: <div>
                <p>Date: Fri, 23rd Dec, 2022</p>
                <p>Time: 11am</p>
            </div>,
            tickets: <div>
                <p>FREE</p>
            </div>,
        },
        {
            key: '2',
            event: <p>A Comedy Show with Igwe Henry</p>,
            details: <div>
                <p>Time: 4pm</p>
            </div>,
            tickets: <div>
                <p>Adults: N5,000</p>
                <p>Children (8-14): N2,500</p>
            </div>,
        }
    ];
    // day 5
    const day5DataSource = [
        {
            key: '1',
            event: <p>A Comedy Show with Akpors and Friends</p>,
            details: <div>
                <p>Date: Sat, 24th Dec, 2022</p>
                <p>Time: 6pm</p>
            </div>,
            tickets: <div>
                <p>Adults: N5,000</p>
                <p>Children (8-14): N2,500</p>
            </div>,
        },
        {
            key: '2',
            event: <p>Sip and Paint (Optional)</p>,
            details: <div>
                <p>Time: 3pm</p>
            </div>,
            tickets: <div>
                <p>N10,000</p>
            </div>,
        },
        {
            key: '3',
            event: <p>Live Sketches</p>,
            details: <div>
                <p>Time: 4pm</p>
            </div>,
            tickets: <div>
                <p>N2,000</p>
            </div>,
        },
        {
            key: '2',
            event: <p>Face Painting (Children)</p>,
            details: <div>
                <p>Time: 4pm</p>
            </div>,
            tickets: <div>
                <p>N1,000</p>
            </div>,
        }
    ];

    return (
        <div ref={ref} className="agt-design full-sales-copy">
            <div className="total-cover">
                <div className="agt-sales">
                    <div>
                        <div className="first-bar">
                            {/* <h2>It's Time To let your Talent shine.</h2> */}
                            <p> &bull; A 5 days event not to be missed</p>
                            <p> &bull; Time to unwind, have fun, network and celebrate Art...</p>
                        </div>
                    </div>
                    <div className="center-me">
                        <h1>LAGOS THEATRE IGANDO</h1>
                        <p>December 20th - 24th In Alimosho, Lagos.</p>
                        <video
                            // poster={Colors} 
                            playsInline={true}
                            src="https://lagostheatrevideos.s3.amazonaws.com/_1.mp4" autoPlay={true} muted loop />
                        <h4>This is what happens when you mix art with maximum fun</h4>
                        <p>
                            Are you ready to have maximum fun?
                        </p>
                        <p>
                            What a year 2022 has been!
                        </p>
                        <p>
                            We know we have all had our fair share of ups and downs these past 11 months.
                        </p>
                        <p>We know a lot has happened.</p>
                        <p>But December is here, and it's time to put all that behind us.</p>
                        <div>
                            <div className="grid_4">
                                <img src={Hero1} alt="hero 1" />
                                <img src={Hero2} alt="hero 1" />
                                <img src={Hero3} alt="hero 1" />
                                <img src={Hero4} alt="hero 1" />
                            </div>
                        </div>
                        <p>The last month of the year is a time of celebration and merriment.</p>
                        <p>A time to come together and experience the uniqueness of art.</p>
                        <p>A time for you and your family to come together and create memories that last a lifetime.</p>
                        <p>And if you're looking for fun, arts, and entertainment events to attend this December, we've
                            got the right one for you at LAGOS THEATRE IGANDO.</p>
                        <p>A 5 days event that embodies the essence of arts and entertainment.</p>
                        <p>What better way to celebrate the end of 2022 than a 5 days event filled with
                            games, music, comedies, arts, culture and unlimited entertainment?</p>
                        <img src={Event1} alt="event image" className="inline-event-image" />
                        <p>There is so much art and entertainment to uncover at Lagos Theatre Igando From December 20th -24th.</p>
                        <p>Come along with friends and families to take sensational experience of arts and entertainment.</p>
                        <p>This is an event you do not want to miss.</p>
                    </div>
                    <div className="schedule mt-5">
                        <h3>👉 Event Schedule</h3>
                        <Tabs type="card">
                            <TabPane key="1" tab="Day 1">
                                <div className="extra-story first">
                                    <div className="first-edit grid_">
                                        <div>
                                            {/* <h3 className="mt-5">👉 Day 1.</h3> */}
                                            <img src={Hero2} alt="Hero1" className="inline-hero" />
                                            <p>
                                                We hit the ground running on Day 1 with our Alimosho Talent Fiesta. Come and watch
                                                as the people of Igando community mesmerise us with their amazing talent.
                                            </p>
                                            <p>
                                                But that's not all...
                                            </p>
                                            <p>There's also going to be a stage play by the...</p>
                                            <Table bordered dataSource={day1DataSource} columns={calenderColumns} />
                                            <a
                                                target="_blank"
                                                href="https://selar.co/dinz">Click to buy ticket for Day 1</a>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane key="2" tab="Day 2">
                                <div className="extra-story second">
                                    <div className="second-edit grid_">
                                        <div>
                                            {/* <h3 className="mt-5">👉 Day 2</h3> */}
                                            <img src={Day2} alt="Hero1" className="inline-hero" />
                                            <p>
                                                Start the day with enthralling music from Aloyinlapa and crew, but the fun does not stop there.
                                            </p>
                                            <div>
                                                <p>We're also going to be having a breathtaking stage play from the...</p>
                                                <Table bordered dataSource={day2DataSource} columns={calenderColumns} />
                                                <a
                                                    target="_blank"
                                                    href="https://selar.co/31og">Click to buy ticket for Day 2</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane key="3" tab="Day 3">
                                <div className="extra-story second">
                                    <div className="second-edit grid_">
                                        <div>
                                            <div>
                                                {/* <h3 className="mt-5">👉 Day 3</h3> */}
                                                <img src={Hero3} alt="Hero1" className="inline-hero" />
                                                <p>
                                                    Our Day 3 is all about our Choral Performance by the amazing Bellarome Chorale.
                                                </p>
                                                <div>
                                                    <Table bordered dataSource={day3DataSource} columns={calenderColumns} />
                                                    <a target="_blank" href="https://selar.co/vv4n">Click to buy ticket for Day 3</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane key="4" tab="Day 4">
                                <div className="extra-story second">
                                    <div className="second-edit grid_">
                                        <div>
                                            {/* <h3 className="mt-5">👉 Day 4</h3> */}
                                            <img src={Day4} alt="Hero1" className="inline-hero" />
                                            <p>
                                                We kickstart the event for day 4 with The Grande Finale of the Alimosho Talent Fiesta
                                            </p>
                                            <p>
                                                Who among the amazing talent will be crowned, championed and walk away with 250k?
                                            </p>
                                            <p>
                                                Come and find out on day 4.
                                            </p>
                                            <p>
                                                There's also going to be a comedy show with Igwe Henry. So come and have
                                                a good laugh as Igwe Henry crack our ribs.
                                            </p>
                                            <div>
                                                <Table bordered dataSource={day4DataSource} columns={calenderColumns} />
                                                <a target="_blank" href="https://selar.co/vls4">Click to buy ticket for Day 4</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane key="5" tab="Day 5">
                                <div className="extra-story second">
                                    <div className="second-edit grid_">
                                        <div>
                                            {/* <h3 className="mt-5">👉 Day 5</h3> */}
                                            <img src={Day5} alt="Hero1" className="inline-hero" />
                                            <p>
                                                Our Day 5 is all about laughter and art.
                                            </p>
                                            <p>
                                                Enjoy breathtaking comedy shows with award-winning comedian Akpos and friends.
                                            </p>
                                            <p>
                                                There are also going to be lots of art activities like Sip and Paint, Live sketches.
                                            </p>
                                            <div>
                                                <Table bordered dataSource={day5DataSource} columns={calenderColumns} />
                                                <div className="inline-flex">
                                                <a target="_blank" href="https://selar.co/4e44">Click to buy ticket for Akpors and Friends</a>
                                                <a target="_blank" href="https://selar.co/o3tm">Click to buy ticket for Sip and Paint</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <p>As you can see, this is not an event to miss.</p>
                                            <p>This December is not the time to stay at home and watch TV,</p>
                                            <p>It's time to create memories and unwind.</p>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    <Divider />
                    <div>
                        <h3 className="mt-5">👉 About Lagos Theater Igando</h3>
                        <p>Lagos Theatre Igando is a community hub for Arts, culture, and
                            entertainment located in Igando, Lagos.</p>
                        <p>We are an Art venue where kids' and adults' creativity is being
                            nurtured.</p>
                        <p>We're working in line with the mission of the Lagos state government to develop
                            talented persons, nurture raw talents and inculcate the culture of appreciating
                            theatrical works into society by engaging children and adults alike.</p>
                        <p>That's why we're inviting you to our 5 days events.</p>
                        <p>Come and experience true art with massive fun.</p>
                    </div>
                </div>
                <div className="tickets-bar">
                    <div className="desktop-only">
                        <h2>Click here to learn more and register! <a
                            target="_blank"
                            href="https://selar.co/m/lagostheatre_igando">Buy Your Tickets</a></h2>
                    </div>
                    <div className="mobile-only">
                        <h2><a
                            target="_blank"
                            href="https://selar.co/m/lagostheatre_igando">Click here to Buy Your Tickets</a></h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default _5DayEventCalender;