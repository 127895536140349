import "../mini.css";

import React from "react";

import Footer from "../../../utils/footer";

import { Link } from "react-router-dom";
import { Divider } from "antd";

import AppRoutes from "../../../utils/routes";
import ReactPlayer from 'react-player';

import Tafta from "../../../assets/images/content/tafta.jpeg";

import SideNav from "../../../utils/sidenav";
import Nav from "../../../utils/nav";
const Trainings = () => {
    return (
        <div className="trainings visit">
            <Nav />
            <div className="training_hero_1">

            </div>
            <div className="contain">
                <div>
                    <div className="grid_2_bias mt-5">
                        <div className="grid_block_right">
                            <div className="mini_story">
                                <div>
                                    <h5 className="page-header-text page_summary">Explore our Programs and Resources</h5>
                                    <div className="desktop-only video_cover">
                                        <ReactPlayer width="100%" height="100%" playsinline={true}
                                            url="https://lagostheatrevideos.s3.amazonaws.com/training2.mp4" 
                                            playing={false} controls={true} loop={true} />
                                    </div>
                                    <h5 className="page_summary sec page-header-summary">At the Lagos Theatre, Igando, we strive to engage and uplift
                                        all learners though creative education-in ways that spark curiosity and build empathy.</h5>
                                    <Divider />
                                    <div>
                                        <h5 className="page_summary sec">LTI Creative Club</h5>
                                        <p>
                                            The Lagos Theatre Igando, because of its passion for Child and Children development
                                            and Empowerment introduced the LTI Creative Club for the children of Alimosho
                                            and every other community. Activities include but are not limited to Music ,Dance
                                            , Drama , Chess , Coding , Tie and Dye , Knitting , Visual arts and painting
                                            , Traditional African Drums (Making & Playing).
                                        </p>
                                        <Link to={AppRoutes.lti}>Learn More</Link>
                                    </div>
                                    <Divider />
                                    <div>
                                        <img src={Tafta} alt="tafta flier" />
                                        <h5 className="page_summary sec">TAFTA Partnership with Terra Kulture</h5>
                                        <p>
                                            Leading culture and art centre, Terra Kulture, in partnership with Mastercard
                                            Foundation, has announced the launch of a new learning cohort for its art
                                            academy, TAFTA (Terra Academy for the Arts). Applications for the free-to-attend
                                            training would commence soon.
                                            Lagos Theatre Igando is proud to be one of the training centers for this initiative.
                                        </p>
                                        <Link to={AppRoutes.tafta}>Learn More</Link>
                                    </div>
                                    <Divider />
                                    <div>
                                        <div className="video_cover">
                                        <ReactPlayer width="100%" height="100%" playsinline={true}
                                            url="https://lagostheatrevideos.s3.amazonaws.com/training3.mp4" 
                                            playing={false} controls={true} loop={true} />
                                        </div>
                                        <h5 className="page_summary sec">Summer Intensive Program/Summer School Graduation</h5>
                                        <p>
                                            The Lagos Theatre Igando holds summer intensive program every August. It is a
                                            fun and learning program for children between the ages of 4 and 16. Curricula
                                            include music, dance, drama, comedy, visual arts, and coding/robotics. A
                                            graduation ceremony holds after the program and parents of participants at the
                                            summer program gets to see their children on stage.
                                        </p>
                                    </div>
                                    <Divider style={{ marginBottom: 0 }} />
                                </div>
                            </div>
                        </div>
                        <SideNav />
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default Trainings;