import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Divider, notification, Skeleton, Modal } from "antd";
import axiosCall from "../../../utils/axiosCall";
import dayjs from 'dayjs';
import Footer from "../../../utils/footer";

import Colors from "../../../assets/images/content/colors.JPG";

import Valentine from "../../../assets/images/content/valentine.jpeg";
import Flag from "../../../assets/images/content/flag.jpeg";
import Journey from "../../../assets/images/content/journey.jpeg";
import Christmas from "../../../assets/images/content/christmas.JPG";
import Arts from "../../../assets/images/content/arts.jpeg";
import Easter from "../../../assets/images/content/easter.jpeg";
import Children from "../../../assets/images/content/children.jpeg";
import Ileya from "../../../assets/images/content/ileya.jpeg";
import Ileya2 from "../../../assets/images/content/ileya2.jpeg";
import Theatre from "../../../assets/images/content/theatre.jpeg";
import Summer from "../../../assets/images/content/summer.jpeg";
import Film from "../../../assets/images/content/film.jpeg";
import Visual from "../../../assets/images/content/visual.jpeg";
import ArtsFiesta from "../../../assets/images/content/arts_fiesta.jpeg";
import NewYear from "../../../assets/images/content/about4.jpg";
import Nav from "../../../utils/nav";

const Events = () => {

    const [fetchingVideosData, setFetchingVideosData] = useState(false);
    const [videosData, setVideosData] = useState([]);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            description: message
        });
    };

    useEffect(() => {
        axiosCall.get('/events')
            .then(videoData => {
                if (videoData.data.statusMessage === "success") {
                    setFetchingVideosData(false);
                    setVideosData(videoData.data.message);
                } else {
                    openNotificationWithIcon('error', videoData.data.summary);
                }
            })
            .catch(err => {
                openNotificationWithIcon('error', 'An error occurred while fetching event videos. Please reload page to try again')
            })
    }, [])
    let skeleton = [];
    for (let i = 0; i < 8; i++) {
        skeleton.push(<Skeleton active />)
    }


    return (
        <div className="events">
            <Nav />
            <div className="nav_cover">

            </div>
            <div className="events_bg">
                <video
                    poster={Colors} playsInline={true}
                    src="https://lagostheatrevideos.s3.amazonaws.com/_1.mp4" autoPlay muted loop />
                <div className="black_overlay">

                </div>
            </div>
            <div>
                <div className="mt-5">
                    <div className="contain">
                        <h3 className="tile_header">What’s On Now</h3>
                        <Divider style={{ marginTop: 0 }} />
                        <div>
                            <div>
                                <div className="grid_4">
                                    {
                                        !fetchingVideosData ?
                                            videosData.length ?
                                                videosData.reverse().map((videos, index) => (
                                                    <div className="homepage_event_cover" key={index}>
                                                        <div className="homepage_event_header">
                                                            <img src={videos.displayImage} alt="_1" />
                                                        </div>
                                                        <div className="homepage_event_body">
                                                            <h2 className="event_title">{videos.eventTitle}</h2>
                                                            <p className="event_date">{dayjs(videos?.eventStarts).format('dddd, MMMM D, YYYY')}</p>
                                                            <p className="event_description">
                                                                {videos.eventDescription}
                                                            </p>
                                                            <Link to={`/tickets/${videos?.id}/44242424820/${videos?.eventTitle}`} className="btn_black">Buy Tickets</Link>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                skeleton.map((placeHolder, index) => (
                                                    <div className="item" key={index}>
                                                        {placeHolder}
                                                    </div>
                                                ))
                                            :
                                            <div>
                                                {skeleton.map((placeHolder, index) => (
                                                    <div className="item" key={index}>
                                                        {placeHolder}
                                                    </div>
                                                ))}
                                            </div>
                                    }
                                    {/* <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Valentine} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Love Vibes (A musical Concert)</h2>
                                            <p className="event_date">February 14  &mdash; Valentine Day Show</p>
                                            <p className="event_description">
                                                It will feature a VIP Cocktail Event where VIP guests will enjoy extra thrills
                                                including food, drinks and cocktails, and a Meet and Greet with top
                                                celebrities (for example, Jonny Drille).
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Easter} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Easter Weekend</h2>
                                            <p className="event_date">April 16</p>
                                            <p className="event_description">
                                                A comedy show that will feature one mainstream comedian and
                                                another from the Alimosho community.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Theatre} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Alimosho Monthly Theatre Production</h2>
                                            <p className="event_date">April 17  &mdash; Monthly Production</p>
                                            <p className="event_description">
                                                This monthly production will take place every third Sunday of the
                                                month. It is a great opportunity for production houses out of Alimosho
                                                to present their theatrical shows.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Flag} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Alimosho Fitness Marathon</h2>
                                            <p className="event_date">May 1 2023  &mdash; Monthly Production</p>
                                            <p className="event_description">
                                                This will involve Dancerapy in collaboration with other ﬁtness houses
                                                and gyms in Alimosho. It will be an outdoors event and require a
                                                stage, lights, sound, as well as food and drinks.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Children} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Alimosho Children's Day Show</h2>
                                            <p className="event_date">May 27 & 28, 2023</p>
                                            <p className="event_description">
                                                This will be an outdoor event featuring bouncing castles, a swimming
                                                pool, DJ, Barney and Mickey Mouse costumes and so on.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Ileya} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Alimosho Dance Exhibition</h2>
                                            <p className="event_date">July 30, 2023  &mdash; Ileya</p>
                                            <p className="event_description">
                                                This will feature various dance groups out of
                                                Alimosho presenting their unique skills
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Ileya2} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Comedy Fiesta - Ileya Edition</h2>
                                            <p className="event_date">July 31, 2023</p>
                                            <p className="event_description">
                                                An assortment of comedy acts from Alimosho and
                                                otherwise doing their acts.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Summer} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Summer Intensive Program / Summer School Graduation</h2>
                                            <p className="event_date">August 1 &mdash; September 2</p>
                                            <p className="event_description">
                                                The Summer School is a fun and learning program for children between the ages
                                                of 4 and 16. Curricula include music, dance, drama, comedy, visual arts, and coding.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Film} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Alimosho Creative Academy</h2>
                                            <p className="event_date">September &mdash; March ; April &mdash; August</p>
                                            <p className="event_description">
                                                It is a professional and daily training provided to young and aspiring
                                                talents of Alimosho in the area of creative and performing arts. It is
                                                a focused eﬀort towards the discovery, grooming and promotion of
                                                artistic talent in Alimosho.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Visual} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Alimosho Visual Arts Exhibition</h2>
                                            <p className="event_date">September 17, 2022</p>
                                            <p className="event_description">
                                                This exhibition is to encourage the display and promotion
                                                of local talents and their works to potential buyers and collectors.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Flag} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Grand Finale: Battle of the Talents</h2>
                                            <p className="event_date">October 1 &mdash; Independence day show</p>
                                            <p className="event_description">
                                                This is a talent show and competition that will feature
                                                dancing, singing, showmanship and so on. The top three winners will
                                                be selected and rewarded with prizes and an opportunity to be
                                                admitted into the Alimosho Creative Academy
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Arts} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Alimosho Food, Arts and Crafts Exhibition</h2>
                                            <p className="event_date">October 29, 2022</p>
                                            <p className="event_description">
                                                This exhibition is a fun and exciting day for the families in the
                                                Alimosho community. It will encourage local talents to display and
                                                sell their craftworks in open tents. It will also feature tents
                                                DJ, food and drinks, caterers and food vendors, mobile cinemas, and
                                                a children's play area
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Journey} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">A Journey through Genres</h2>
                                            <p className="event_date">November 13, 2022</p>
                                            <p className="event_description">
                                                A music concert with the number 1 Choir group in Alimosho with over 60 singers
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={ArtsFiesta} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Lagos Theate Igando Art Fiesta</h2>
                                            <p className="event_date">December 27th - 31st, 2022</p>
                                            <p className="event_description">
                                                This Christmas Special will feature three (3) main
                                                headlining dance, music and comedy acts extravaganza.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={Christmas} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Family Christmas Party Celebration</h2>
                                            <p className="event_date">December 26, 2022</p>
                                            <p className="event_description">
                                                This is a fun end of the year activity for families in the
                                                community to celebrate with their loved ones. The events are
                                                designed to entertain the children.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div>
                                    <div className="homepage_event_cover">
                                        <div className="homepage_event_header">
                                            <img src={NewYear} alt="_1" />
                                        </div>
                                        <div className="homepage_event_body">
                                            <h2 className="event_title">Alimosho New Year's Party</h2>
                                            <p className="event_date">January 1, 2023</p>
                                            <p className="event_description">
                                                This is a fun activity for families in the community to celebrate
                                                the new year with their loved ones. The occasion will feature
                                                bouncing castles and trampoline, beauty pageant, swimming,
                                                games, face-painting, play station games, Santa Claus visit, Xmas
                                                goodie bag, food and drinks.
                                            </p>
                                            <Link to="/tickets" className="btn_black">Buy Tickets</Link>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default Events;