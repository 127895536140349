import "../mini.css";

import React from "react";

import Footer from "../../../utils/footer";

import Visit2 from "../../../assets/images/content/visit2.jpeg";
import SchoolVisit from "../../../assets/images/content/schoolvisit.jpeg";
import SideNav from "../../../utils/sidenav";
import Nav from "../../../utils/nav";

const Trainings = () => {
    return (
        <div className="trainings visit">
            <Nav />
            <div className="lti_hero_1">
                <video src="https://lagostheatrevideos.s3.amazonaws.com/visit.mp4" playsInline={true} autoPlay muted loop />
                <div className="black_overlay">
                </div>
            </div>
            <div className="contain">
                <div>
                    <div className="grid_2_bias mt-5">
                        <div className="grid_block_right">
                            <div className="mini_story">
                                <div className="desktop-only">
                                    <img src={Visit2} alt="visit" />
                                </div>
                                <h5 className="page_summary">Explore the interior and exterior outdoor ground of the Lagos Theatre, Igando.</h5>
                                <p>The Lagos Theatre, Igando is one of the four theatres set up by the Lagos State
                                    government - Ministry of Tourism, Arts and Culture. Since activities commenced
                                    in the Second Quarter of 2021, the Lagos Theatre Igando has hosted several
                                    art-themed and entertainment events including exhibitions, plays, shows, community
                                    celebrations, and activities for children.
                                    Plan your own type of visit to this must-see in Alimosho.
                                </p>
                                {/* <div className="mobile-only" style={{ width: '100%', height: '100%' }}>
                                    <video src="https://lagostheatrevideos.s3.amazonaws.com/visit.mp4" autoPlay
                                    style={{ width: '100%', height: '100%' }}
                                    muted loop />
                                </div> */}
                                <p>
                                    The current
                                    hours of public access are Monday - Sunday, 9a.m. - 6p.m.
                                </p>
                                <div className="desktop-only">
                                    <img src={SchoolVisit} alt="visit" />
                                </div>
                                <div className="mobile-only">
                                    <img src={Visit2} alt="visit" />
                                </div>
                            </div>
                        </div>
                        <SideNav />
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default Trainings;