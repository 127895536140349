import "../mini.css";

import React, { useState } from "react";

import Footer from "../../../utils/footer";

import Bimbo from "../../../assets/images/team/bimbo.jpeg";
import Nav from "../../../utils/nav";

const DirectorBio = () => {
    const [hideMe, setHideMe] = useState(false);
    return (
        <div className="visit">
            <Nav />
            {/* <div className="team_hero_1"> */}
            <div className="about_sect">
                <img src={Bimbo} alt="" />
            </div>
            {/* </div> */}
            <div className="contain">
                <div>
                    <div className="container mt-5">
                        <div className="mini_story">
                            <p>
                                <h1 className="inline-header">Mr Obafunwa Abimbola</h1> &mdash; Is a Performing arts catalyst. He’s  responsible for the development
                                and empowerment of hundreds of young Dance professionals in Nigeria , With an influence on
                                over 10 Million Dance Professionals Nationwide  in the last decade. He is the Director of
                                Corporate Dance World and Entertainment ; an agency in Lagos
                                Nigeria, known for the most spectacular dance performances and productions, while providing
                                Job opportunities for hundreds of professional dancers within the Country
                            </p>
                            <div>
                                {
                                    hideMe ?
                                        <div>
                                            <p>
                                                He is also the President of the First Dance Training Foundation in the country "The Dance
                                                Deal Training Foundation"; which has trained over  10 sets of  young dance professionals
                                                and is currently training its 11th set. This initiative has directly produced over 400
                                                dance professionals since 2009. For 10 years Abimbola worked as the Dance Director of
                                                Africa’s Biggest Dance reality TV Show-“Maltina Dance All”. A show that helped to
                                                revolutionize the perception of Dance in Africa as a whole. This has since made him
                                                a role model to millions of practitioners across the nation.
                                            </p>
                                            <p>
                                                In 2016, Abimbola was appointed a member of the Lagos at 50 planning committee by His
                                                Excellency, The Executive Governor of Lagos State, Mr Akinwumi Ambode.
                                                "My Vision is to help build a sustainable structure for the Creative Industry in Nigeria
                                                Starting from Lagos, while empowering as many as possible youths gifted in the Art form."
                                            </p>
                                        </div>
                                        :
                                        ''
                                }
                            </div>
                            <button
                                onClick={() => { setHideMe(!hideMe) }}
                                className="btn_red">
                                {!hideMe ? 'Learn More' : 'Hide'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default DirectorBio;