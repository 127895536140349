import "./utils.css";

import React, { useState, useEffect } from "react";

import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal, Input, Drawer, Divider } from 'antd';
import { Link } from "react-router-dom";

import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// import Logo from "../assets/images/logo.jpeg";
import Logo from "../assets/images/logo.png";

import AppRoutes from "./routes";

const Nav = () => {

    const [open, setOpen] = useState(false);
    const [extraNavToShow, setExtraNavToShow] = useState(0);

    const validator = yup.object().shape({
        emailAddress: yup.string().email('Email is not valid').required('Email field can not be empty'),
        password: yup.string().min(6).required('Password field can not be empty')
    })

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            emailAddress: "",
            password: "",
        },
        resolver: yupResolver(validator)
    });

    const [fixedNav, setFixed] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsModalOpen(true)
    //     }, 12000)
    // }, [])

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const rentals = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <Link
                            to={AppRoutes.generalInfo}>
                            General Information
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <Link
                            to={AppRoutes.rent}>
                            Rent Our Space
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
                {
                    key: '1',
                    label: (
                        <Link
                            to={AppRoutes.visit}>
                            How to get Here
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                }
            ]}
        />
    )
    const explore = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <Link
                            to={AppRoutes.events}>
                            What’s On?
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <Link
                            to={AppRoutes.explore}>
                            Explore our Videos
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                }
            ]}
        />
    )
    const donate = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <Link
                            to={AppRoutes.donate}>
                            Donate
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <Link
                            to={AppRoutes.howToGive}>
                            How to Donate
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <Link
                            to={AppRoutes.plans}>
                            Donation Plans
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
            ]}
        />
    )
    const about = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <Link
                            to={AppRoutes.about}>
                            Our Story
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <Link
                            to={AppRoutes.team}>
                            Administrative Staff
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
                // {
                //     key: '3',
                //     label: (
                //         <Link
                // to={AppRoutes.director}>
                //             Administrator / Artistic Director Bio
                //             <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                //         </Link>
                //     ),
                // }
            ]}
        />
    )
    const school = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <Link
                            to={AppRoutes.trainings}>
                            Training Programmes
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <Link
                            to={AppRoutes.lti}>
                            LTI
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <Link
                            to={AppRoutes.tafta}>
                            TAFTA
                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                        </Link>
                    ),
                }
            ]}
        />
    )

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 200) {
                setFixed(true);
            }
            else {
                setFixed(false);
            }
        })
    }, [])

    const setExtraNavDisplay = e => extraNavToShow === e ? setExtraNavToShow(0) : setExtraNavToShow(e);

    return (
        <div className={`navigation ${fixedNav ? 'fixed' : ''}`}>
            <div>
                <Link
                    onClick={() => setOpen(false)}
                    to="/">
                    <img src={Logo} alt="logo" />
                </Link>
            </div>
            <div className="desktop-only">
                <ul>
                    <li>
                        {/* <Dropdown overlay={shop}> */}
                        <Link
                            onClick={() => setOpen(false)}
                            to={AppRoutes.home}>
                            Home
                        </Link>
                        {/* </Dropdown> */}
                    </li>
                    <li>
                        <Dropdown overlay={about}>
                            <Link
                                onClick={() => setOpen(false)}
                                to="#">
                                About
                            </Link>
                        </Dropdown>
                    </li>
                    <li>
                        <Dropdown overlay={explore}>
                            <Link
                                onClick={() => setOpen(false)}
                                to="#">
                                Explore
                            </Link>
                        </Dropdown>
                    </li>
                    <li>
                        <Dropdown overlay={school}>
                            <Link
                                onClick={() => setOpen(false)}
                                to="#">
                                Classes & Trainings
                            </Link>
                        </Dropdown>
                    </li>
                    <li>
                        <Dropdown overlay={rentals}>
                            <Link
                                onClick={() => setOpen(false)}
                                to="#">
                                Rentals & Visits
                            </Link>
                        </Dropdown>
                    </li>
                    <li>
                        {/* <Dropdown overlay={shop}> */}
                        <Link
                            onClick={() => setOpen(false)}
                            to={AppRoutes.shop}>
                            Shop
                        </Link>
                        {/* </Dropdown> */}
                    </li>
                    <li>
                        <Dropdown overlay={donate}>
                            <Link
                                onClick={() => setOpen(false)}
                                to="#">
                                Support
                            </Link>
                        </Dropdown>
                    </li>
                </ul>
            </div>
            <div className="desktop-only">
                <ul>
                    <li>
                        <Link
                            onClick={() => setOpen(false)}
                            to={AppRoutes.newsroom}>
                            NewsRoom
                        </Link>
                    </li>
                    <li className="newsletter">
                        <button onClick={() => setIsModalOpen(true)}>
                            Join Our Newsletter
                        </button>
                    </li>
                </ul>
            </div>
            <div className="mobile-only">
                {/* <Link to="/">
                    <ion-icon
                        class="menu-bar"
                        name="cart-outline"></ion-icon>
                </Link> */}
                <ion-icon
                    onClick={showDrawer}
                    class="menu-bar" name="menu-outline"></ion-icon>
            </div>
            <Modal title={null} footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="modal_content">
                    <h3>Join our Tribe</h3>
                    <p>The Lagos Theatre Igando focuses on community development through the art. Join our
                        Tribe today to get up-to-date information on art related activities at the Theatre.</p>
                    <div className="form-group">
                        <Controller name="password" control={control}
                            render={({ field }) => {
                                return (
                                    <Input style={{ height: '5rem' }} type="email" {...field}
                                        placeholder="example@mail.com"
                                        name="emailAddress" />
                                )
                            }} />
                        {errors.password && <p className="errorMessage">{errors.password.message}</p>}
                    </div>
                    <button className="btn_red"> Join our Newsletter</button>
                </div>
                {/* <div className="africa">
                    <img src={Africa1} alt="Africa1" />
                </div> */}
            </Modal>
            <Drawer
                className="nav-drawer"
                title={null} placement="right" onClose={onClose} open={open}>
                <div className="drawer-nav-block">
                    <div>
                        <img src={Logo} alt="logo" />
                    </div>
                    <ion-icon
                        onClick={onClose}
                        class="menu-bar"
                        name="close-outline"></ion-icon>
                </div>
                <ul>
                    <li>
                        <Link
                            onClick={() => setOpen(false)}
                            to="">
                            <span>Home</span>
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={e => { e.preventDefault(); setExtraNavDisplay(1) }}
                            to="">
                            <span>About</span>
                            <ion-icon name="add-outline"></ion-icon>
                        </Link>
                        {/* </li> */}
                        <div className={`inner-drawer-nav ${extraNavToShow === 1 ? 'show-extra' : 'hide-extra'}`}>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.about}>Our Story</Link>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.team}>Administrative Staff</Link>
                        </div>
                    </li>
                    <li>
                        <Link
                            onClick={e => { e.preventDefault(); setExtraNavDisplay(2) }}
                            to="">
                            <span>Explore</span>
                            <ion-icon name="add-outline"></ion-icon>
                        </Link>
                        <div className={`inner-drawer-nav ${extraNavToShow === 2 ? 'show-extra' : 'hide-extra'}`}>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.events}>What’s On?</Link>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.explore}>Explore our Videos</Link>
                        </div>
                    </li>
                    <li>
                        <Link
                            onClick={e => { e.preventDefault(); setExtraNavDisplay(3) }}
                            to="">
                            <span>Classes and Trainings</span>
                            <ion-icon name="add-outline"></ion-icon>
                        </Link>
                        <div className={`inner-drawer-nav ${extraNavToShow === 3 ? 'show-extra' : 'hide-extra'}`}>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.trainings}>Training Programmes</Link>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.lti}>LTI</Link>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.tafta}>TAFTA</Link>
                        </div>
                    </li>
                    <li>
                        <Link
                            onClick={e => { e.preventDefault(); setExtraNavDisplay(4) }}
                            to="">
                            <span>Rentals and Visits</span>
                            <ion-icon name="add-outline"></ion-icon>
                        </Link>
                        <div className={`inner-drawer-nav ${extraNavToShow === 4 ? 'show-extra' : 'hide-extra'}`}>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.generalInfo}>General Information</Link>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.rent}>Rent Our Space</Link>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.visit}>How to get Here</Link>
                        </div>
                    </li>
                    <li>
                        <Link
                            onClick={() => setOpen(false)}
                            to={AppRoutes.shop}>
                            <span>Shop</span>
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={e => { e.preventDefault(); setExtraNavDisplay(5) }}
                            to="">
                            <span>Support</span>
                            <ion-icon name="add-outline"></ion-icon>
                        </Link>
                        <div className={`inner-drawer-nav ${extraNavToShow === 5 ? 'show-extra' : 'hide-extra'}`}>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.donate}>Donate</Link>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.howToGive}>How to Donate</Link>
                            <Link
                                onClick={() => setOpen(false)}
                                to={AppRoutes.plans}>Donation Plans</Link>
                        </div>
                    </li>
                    <li>
                        <Link
                            onClick={() => setOpen(false)}
                            to={AppRoutes.newsroom}>
                            <span>Newsletter</span>
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                        </Link>
                    </li>
                </ul>
            </Drawer>
        </div>
    )
}

export default Nav;