import "../mini.css";

import React from "react";

import Nav from "../../../utils/nav";
import Footer from "../../../utils/footer";

import IgandoMap from "../../../assets/images/content/igando_map.png";
import SideNav from "../../../utils/sidenav";

const Trainings = () => {
    return (
        <div className="visit">
            <Nav />
            <div className="how_to_get_here">
                {/* <video sr/c={VisitVideo} autoPlay muted loop /> */}
            </div>
            <div className="contain">
                <div>
                    <div className="grid_2_bias mt-5">
                        <div className="grid_block_right">
                            <div className="mini_story">
                                <img src={IgandoMap} alt="visit" />
                                <h5 className="page_summary">88, College Street, NYSC bus stop Igando.</h5>
                                <p>No matter where you are coming from, we’ve got you covered.</p>
                            </div>
                        </div>
                        <SideNav />
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default Trainings;