import "./homepage.css";
import 'react-ig-feed/dist/index.css';

import React from "react";
import { Divider } from "antd";
import { Link } from "react-router-dom";

import Footer from "../../utils/footer";
import AppRoutes from "../../utils/routes";
import InstagramFeed from 'react-ig-feed';

// import HeroVideo from "../../assets/video/hero.mp4";

import Singer from "../../assets/images/content/singer.jpg";

import Valentine from "../../assets/images/content/valentine.jpeg";
import Flag from "../../assets/images/content/flag.jpeg";
import Easter from "../../assets/images/content/easter.jpeg";
import Children from "../../assets/images/content/children.jpeg";
import Ileya from "../../assets/images/content/ileya.jpeg";
import Ileya2 from "../../assets/images/content/ileya2.jpeg";
import Theatre from "../../assets/images/content/theatre.jpeg";
import Summer from "../../assets/images/content/summer.jpeg";
import Nav from "../../utils/nav";

const Homepage = () => {
    return (
        <div className="homepage">
            <Nav />
            <div className="homepage_section">
                <div className="hero_section">
                    <video
                        poster={Singer}
                        src="https://lagostheatrevideos.s3.amazonaws.com/home.mp4"
                        // src={HeroVideo}
                        playsInline autoPlay muted loop />
                    <div className="black_overlay">

                    </div>
                    <div className="hero_section_div">
                        <h3>Welcome to the Lagos State Theatre Igando</h3>
                        <p>
                            The Lagos Theatre, Igando is one of four theatres set up by the Lagos State
                            government.
                        </p>
                        <Link to={AppRoutes.about}>Read About Us <ion-icon name="arrow-forward-outline"></ion-icon></Link>
                    </div>
                </div>
            </div>
            <div className="events_tab events-listing mt-4">
                <div className="contain">
                    <div className="grid_flex">
                        <h3 className="tile_header">What’s On Now</h3>
                        <Link to={AppRoutes.events}>See All Events</Link>
                    </div>
                    <Divider style={{ marginTop: 0 }} />
                    <div>
                        <div className="grid_4">
                            <div className="homepage_event_cover">
                                <div className="homepage_event_header">
                                    <img src={Valentine} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Love Vibes (A musical Concert)</h2>
                                    <p className="event_date">February 14  &mdash; Valentine Day Show</p>
                                    <p className="event_description">
                                        It will feature a VIP Cocktail Event where VIP guests will enjoy extra thrills
                                        including food, drinks and cocktails, and a Meet and Greet with top
                                        celebrities (for example, Jonny Drille).
                                    </p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_event_header">
                                    <img src={Easter} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Easter Weekend</h2>
                                    <p className="event_date">April 16</p>
                                    <p className="event_description">
                                        A comedy show that will feature one mainstream comedian and
                                        another from the Alimosho community.
                                    </p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_event_header">
                                    <img src={Theatre} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Alimosho Monthly Theatre Production</h2>
                                    <p className="event_date">April 17  &mdash; Monthly Production</p>
                                    <p className="event_description">
                                        This monthly production will take place every third Sunday of the
                                        month. It is a great opportunity for production houses out of Alimosho
                                        to present their theatrical shows.
                                    </p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_event_header">
                                    <img src={Flag} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Alimosho Fitness Marathon</h2>
                                    <p className="event_date">May 1 2023  &mdash; Monthly Production</p>
                                    <p className="event_description">
                                        This will involve Dancerapy in collaboration with other ﬁtness houses
                                        and gyms in Alimosho. It will be an outdoors event and require a
                                        stage, lights, sound, as well as food and drinks.
                                    </p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_event_header">
                                    <img src={Children} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Alimosho Children's Day Show</h2>
                                    <p className="event_date">May 27 & 28, 2023</p>
                                    <p className="event_description">
                                        This will be an outdoor event featuring bouncing castles, a swimming
                                        pool, DJ, Barney and Mickey Mouse costumes and so on.
                                    </p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_event_header">
                                    <img src={Ileya} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Alimosho Dance Exhibition</h2>
                                    <p className="event_date">July 30, 2023  &mdash; Ileya</p>
                                    <p className="event_description">
                                        This will feature various dance groups out of
                                        Alimosho presenting their unique skills
                                    </p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_event_header">
                                    <img src={Ileya2} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Comedy Fiesta - Ileya Edition</h2>
                                    <p className="event_date">July 31, 2023</p>
                                    <p className="event_description">
                                        An assortment of comedy acts from Alimosho and
                                        otherwise doing their acts.
                                    </p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                            <div className="homepage_event_cover">
                                <div className="homepage_event_header">
                                    <img src={Summer} alt="_1" />
                                </div>
                                <div className="homepage_event_body">
                                    <h2 className="event_title">Summer Intensive Program / Summer School Graduation</h2>
                                    <p className="event_date">August 1 &mdash; September 2</p>
                                    <p className="event_description">
                                        The Summer School is a fun and learning program for children between the ages
                                        of 4 and 16. Curricula include music, dance, drama, comedy, visual arts, and coding.
                                    </p>
                                    {/* <button className="btn_black">Buy Tickets</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner mt-3">
                <div className="banner_story">
                    <div>
                        <h3>Rent our Space for your Events</h3>
                        <p>
                            The Lagos Theatre Igando has hosted several art-themed and entertainment events including
                            exhibitions, plays, shows, community celebrations, and activities for children. Plan your
                            own type of visit to this must-see in Alimosho.
                        </p>
                        <Link to={AppRoutes.rent}>See our Rental Pricing</Link>
                    </div>
                </div>
            </div>
            <div className="social_media events_tab pb-5 pt-5">
                <div className="contain">
                    <h3 className="tile_header">Our Social Media Presence</h3>
                    <Divider style={{ marginTop: 0 }} />
                    <div className="desktop-only">
                        {/* <InstagramFeed token={process.env.REACT_APP_INSTAGRAM_KEY} counter="12" /> */}
                    </div>
                    <div className="mobile-only">
                        {/* <InstagramFeed token={process.env.REACT_APP_INSTAGRAM_KEY} counter="6" /> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Homepage;