import "../mini.css";

import React, { useState } from "react";

import Footer from "../../../utils/footer";

import { Input, Divider, notification, Radio, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { PaystackButton } from 'react-paystack';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { v4 as uuid } from "uuid";

import Payment from "../../../assets/images/payment.svg";
import Drummer from "../../../assets/images/content/drummer.JPG";
import SideNav from "../../../utils/sidenav";
import axiosCall from "../../../utils/axiosCall";
import Nav from "../../../utils/nav";

const Trainings = () => {

    const Navigate = useNavigate();
    const [uuidToken] = useState(uuid());
    const [loadingData, setLoadingData] = useState(false);
    const [donationValue, setDonationValue] = useState(500);
    const [donationOptionToDisplay, setDonationOptionToDisplay] = useState('NGN 500.00');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const antIconLoader = <LoadingOutlined style={{ fontSize: 24, color: '#D43361' }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            description: message
        });
    };
    const digitsOnly = (value) => /^\d+$/.test(value);
    const validator = yup.object().shape({
        firstName: yup.string().required('Please tell us who you are'),
        lastName: yup.string().required('Please tell us who you are'),
        emailAddress: yup.string().email('Email is not valid').required('Email field can not be empty'),
        phoneNumber: yup.string()
            .min(9, 'Please enter a valid phone number')
            .required('Phone number field is required')
            .test('Digits only', 'The field should have digits only', digitsOnly)
    })

    const [userData, setUserData] = useState({});

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(validator)
    });
    const handleFormDataChange = e => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }

    const config = {
        public_key: process.env.REACT_APP_FLUTTERWAVE_KEY,
        tx_ref: uuidToken,
        amount: +donationValue,
        currency: 'NGN',
        payment_options: 'card',
        customer: {
            email: 'help@lagostheatreigando.com',
        },
        customizations: {
            title: 'Lagos Theatre Igando',
            description: ''
        },
    };
    const handleFlutterPayment = useFlutterwave(config);

    const saveUserDonationToDB = e => {
        let { firstName, lastName, emailAddress, phoneNumber } = e;
        axiosCall.post('/save-random-donations', {
            firstName, lastName, emailAddress, phoneNumber
        })
            .then(data => {
                if (data.data.statusMessage === 'success') {
                    setIsModalOpen(true);
                    setLoadingData(false);
                } else {
                    setLoadingData(false);
                    openNotificationWithIcon('error', 'An error occurred while saving your transaction. Please let us know in case your account is charged.');
                }
            })
            .catch(err => {
                setLoadingData(false);
                openNotificationWithIcon('error', 'An error occurred while saving your transaction. Please let us know in case your account is charged.');
            })
    }

    const submitUserDonationData = e => {
        setUserData(e);
        setLoadingData(true);
        handleFlutterPayment({
            callback: async response => {
                if (response.status === "successful") {
                    saveUserDonationToDB(e);
                } else {
                    setLoadingData(false);
                    openNotificationWithIcon('error', 'Transaction cancelled');
                }
                closePaymentModal();
            },
            onClose: () => {
                setLoadingData(false);
                openNotificationWithIcon('error', 'Transaction cancelled')
            }
        });
    }
    const plainOptions = ['NGN 500.00', 'NGN 1,000.00', 'NGN 10,000.00', 'NGN 20,000.00', 'NGN 50,000.00'];
    const onChangeDonationAmount = ({ target: { value } }) => {
        // console.log('radio4 checked', );
        setDonationValue(parseInt(value.split(' ')[1]?.split(',')?.join('')));
        setDonationOptionToDisplay(value);
    };

    return (
        <div className="trainings donate-page visit">
            <Spin spinning={loadingData} indicator={antIconLoader}>
                <Nav />
                <div className="donate_hero_1">

                </div>
                <div className="contain">
                    <div>
                        <div className="grid_2_bias mt-5">
                            <div className="grid_block_right">
                                <div className="mini_story">
                                    <h5 className="page_summary">At the Lagos Theatre Igando, we are committed to collaborating
                                        with the people of Alimosho community and the Lagos State Government towards artistic talent
                                        discovery, promotion and presentation, subsequent community development as well as
                                        facilitating job creation for the creative youths.</h5>
                                    <p>Our Vision is to foster artistic development, appreciation, and engagement among
                                        communities within the Alimosho Local Govt Area. Your gift today will provide vital
                                        support for our artistic and educational programs, both in person and online. You can also
                                        support our activities calendar, artistic and educational programs when you attend our
                                        series of events.</p>
                                    <p>The Theatre is happy to accept contributions via your preferred donation channel, such as
                                        check, online transfer, or cash in person.</p>
                                    <Divider />
                                    <h4 className="co_text_header">Sponsor A Recreational/Outdoor Area </h4>
                                    <p>Be a part of building our Recreational Area (Food Court, Art Gallery, Lounge, Café) and
                                        give your brand an opportunity to be seen by over a hundred (100) thousand people
                                        throughout the year by being a major sponsor for developing our recreational area. </p>
                                    <div className="vidoe">
                                        <img src={Drummer} alt="A musical band drumming" />
                                    </div>
                                    <div>
                                        <form onSubmit={handleSubmit(submitUserDonationData)} autoComplete="false">
                                            <div className="form-group">
                                                <Radio.Group
                                                    options={plainOptions}
                                                    onChange={onChangeDonationAmount}
                                                    value={donationOptionToDisplay}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </div>
                                            <div className="flex_form">
                                                <div className="form-group space">
                                                    <label htmlFor="firstName">First name</label>
                                                    <Controller name="firstName" control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <Input style={{ height: '5rem' }}
                                                                    onChange={(e) => handleFormDataChange(e)}
                                                                    type="text"
                                                                    {...field}
                                                                    name="firstName" />
                                                            )
                                                        }} />
                                                    {errors.firstName && <p className="errorMessage">{errors.firstName.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="lastName">Last name</label>
                                                    <Controller name="lastName" control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <Input style={{ height: '5rem' }}
                                                                    onChange={(e) => handleFormDataChange(e)}
                                                                    type="text"
                                                                    {...field}
                                                                    name="lastName" />
                                                            )
                                                        }} />
                                                    {errors.lastName && <p className="errorMessage">{errors.lastName.message}</p>}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="emailAddress">Email address</label>
                                                <Controller name="emailAddress" control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <Input style={{ height: '5rem' }}
                                                                onChange={(e) => handleFormDataChange(e)}
                                                                type="email"
                                                                {...field}
                                                                name="emailAddress" />
                                                        )
                                                    }} />
                                                {errors.emailAddress && <p className="errorMessage">{errors.emailAddress.message}</p>}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phoneNumber">Phone number</label>
                                                <Controller name="phoneNumber" control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <Input type="tel" style={{ height: '5rem' }}
                                                                {...field}
                                                                name="phoneNumber" />
                                                        )
                                                    }} />
                                                {errors.phoneNumber && <p className="errorMessage">{errors.phoneNumber.message}</p>}
                                            </div>
                                            <div style={{ display: 'block', marginTop: '15px' }}>

                                            </div>
                                            {
                                                loadingData
                                                    ?
                                                    <button className="btn_red">
                                                        <span style={{ marginRight: '10px' }}>Setting up Donation. Please wait...</span>
                                                        <Spin indicator={antIcon} /></button>
                                                    :
                                                    <button className="btn_red">Donate Now</button>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <SideNav />
                        </div>
                    </div>
                    <Modal title={null} open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
                        <div className="successfulDonation">
                            <img src={Payment} alt="payment" />
                            <h3>Thank you for your donation.</h3>
                            <p>Your gift today will provide vital support for our
                                artistic and educational programs, both in person and online.</p>
                            <button className="btn_red"
                                onClick={() => Navigate(0)}>Close</button>
                        </div>
                    </Modal>
                </div>
                <Footer margin={true} />
                <Modal title={null} open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
                    <div className="successfulDonation">
                        <img src={Payment} alt="payment" />
                        <h3>Thank you for your donation.</h3>
                        <p>Your gift today will provide vital support for our
                            artistic and educational programs, both in person and online.</p>
                        <button className="btn_red"
                            onClick={() => Navigate(0)}>Close</button>
                    </div>
                </Modal>
            </Spin>
        </div>
    )
}

export default Trainings;