import "../mini.css";

import React from "react";

import Footer from "../../../utils/footer";

import { Divider } from "antd";

import Tafta from "../../../assets/images/content/tafta2.jpeg";
import SideNav from "../../../utils/sidenav";
import Nav from "../../../utils/nav";
const Trainings = () => {
    return (
        <div className="visit">
            <Nav />
            <div className="tafta_hero_1">

            </div>
            <div className="contain">
                <div>
                    <div className="grid_2_bias mt-5">
                        <div className="grid_block_right">
                            <div className="mini_story">
                                <div>
                                    <h5 className="page_summary">Explore our Programs and Resources</h5>
                                    <div className="video_cover">
                                        <img src={Tafta} className="tafta-img" alt="Tafta" />
                                    </div>
                                    <p>Leading culture and art centre, Terra Kulture, in partnership with Mastercard
                                        Foundation, has announced the launch of a new learning cohort for its art
                                        academy, TAFTA (Terra Academy for the Arts). Applications for the free-to-attend
                                        training would commence soon. Lagos Theatre Igando is proud to be one of the training
                                        centers for this initiative.
                                    </p>
                                    <a className="btn_red"
                                        target="_blank" rel="noreferrer"
                                        href="https://terraacademyforarts.com/tafta-registration-form"
                                        >Click Here to Register</a>
                                    <Divider style={{ marginBottom: 0 }} />
                                </div>
                            </div>
                        </div>
                        <SideNav />
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default Trainings;