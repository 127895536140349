import "../mini.css";

import React, { useState } from "react";

import Footer from "../../../utils/footer";

import { Input, Divider, notification, Modal, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingOutlined } from '@ant-design/icons';

import LTI from "../../../assets/images/content/lti.jpeg";
import LTI2 from "../../../assets/images/content/lti2.jpeg";
import LTI3 from "../../../assets/images/content/lti3.jpeg";
import LTI4 from "../../../assets/images/content/lti4.jpeg";
import axiosCall from "../../../utils/axiosCall";
import SideNav from "../../../utils/sidenav";
import Nav from "../../../utils/nav";

const Trainings = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitLTIInfo, setSubmitLTIInfo] = useState(false);
    const antIcon = (<LoadingOutlined style={{ fontSize: 24, color: '#fff', marginLeft: 15 }} spin />);
    const validator = yup.object().shape({
        firstName: yup.string().required('Please tell us your first name'),
        lastName: yup.string().required('Please tell us your last name'),
        emailAddress: yup.string().email('Email is not valid').required('Email field can not be empty'),
        phoneNumber: yup.string().min(6).required('Phone number field can not be empty')
    })

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            description: message
        });
    };

    const { handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValue: {
            emailAddress: "",
            password: "",
        },
        resolver: yupResolver(validator)
    });
    const submitLTIMembershipData = e => {
        setSubmitLTIInfo(true);
        axiosCall.post('/save-lti-members', {
            firstName: e.firstName,
            lastName: e.lastName,
            emailAddress: e.emailAddress,
            phoneNumber: e.firstName,
        })
            .then(ltiMembership => {
                if (ltiMembership.data.statusMessage === "success") {
                    setIsModalOpen(false);
                    setValue('firstName', '');
                    setValue('lastName', '');
                    setValue('emailAddress', '');
                    setValue('phoneNumber', '');
                    setSubmitLTIInfo(false);
                    openNotificationWithIcon('success', 'Data saved successfully. Please allow us to reach out to you');
                } else {
                    setSubmitLTIInfo(false);
                    openNotificationWithIcon('error', ltiMembership.data.summary);
                }
            })
            .catch(err => {
                setSubmitLTIInfo(false);
                openNotificationWithIcon('error', 'An error occurred while saving your data. Please reload page to try again')
            })
    }
    return (
        <div className="trainings visit">
            <Nav />
            <div className="lti_hero_1">
                <video
                    playsInline={true}
                    src="https://lagostheatrevideos.s3.amazonaws.com/training2.mp4" autoPlay muted loop />
                <div className="black_overlay">
                </div>
            </div>
            <div className="contain">
                <div>
                    <div className="grid_2_bias mt-5">
                        <div className="grid_block_right">
                            <div className="mini_story">
                                <div>
                                    <h5 className="page_summary">Explore our Programs and Resources</h5>
                                    <div className="desktop-only video_cover">
                                        <div className="grid_2 lti_grid">
                                            <img src={LTI} alt="LTI" />
                                            <img src={LTI2} alt="LTI" />
                                        </div>
                                    </div>
                                    <p>
                                        The Lagos Theatre Igando, because of its passion for Child and Children development
                                        and Empowerment introduced the LTI Creative Club for the children of Alimosho
                                        and every other community. Activities include but are not limited to Music ,Dance
                                        , Drama , Chess , Coding , Tie and Dye , Knitting , Visual arts and painting
                                        , Traditional African Drums (Making & Playing).
                                    </p>
                                    <div className="mobile-only video_cover">
                                        <div className="grid_2 lti_grid">
                                            <img src={LTI} alt="LTI" />
                                            <img src={LTI2} alt="LTI" />
                                        </div>
                                    </div>
                                    <p>
                                        There are lots of benefits attached to joining the LTI Creative Club. To mention a few are:
                                        Joining the LTI Creative Club gives your child the opportunity to participate
                                        in International Chess Tournaments. ,Membership of the LTI Creative Club gives your child
                                        20% discount on all theatre events/shows.
                                    </p>
                                    <ul>
                                        <li>Families of the LTI Creative club automatically have a 20% discount on the use of the theatre.</li>
                                        <li>LTI Creative Club membership gives your child the access to the theatre’s Recreation Park.</li>
                                        <li>Also, members are awarded with amazing gifts such as LTI T-shirts, backpacks, water bottles and stationaries</li>
                                        <li>Your child becomes a professional in Dance, Drama and Music</li>
                                        <li>Joining the LTI Creative Club accords your child the privilege of being a top-notch coding expert.</li>
                                        <li>Not forgetting that this Creative Club aids in boosting your child’s self-esteem, confidence
                                            and improve their social and networking skills.</li>
                                    </ul>
                                    <button
                                        onClick={() => setIsModalOpen(true)}
                                        className="btn_red">Click here to register</button>
                                    <Divider />
                                    <div className="grid_2 lti_grid">
                                        <img src={LTI3} alt="LTI" />
                                        <img src={LTI4} alt="LTI" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SideNav />
                    </div>
                </div>
            </div>
            <Modal title={null} open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
                <form onSubmit={handleSubmit(submitLTIMembershipData)} autoComplete="false">
                    <h3>Please let us know who you are</h3>
                    <div className="flex_form">
                        <div className="form-group space">
                            <label htmlFor="firstName">First name</label>
                            <Controller name="firstName" control={control}
                                render={({ field }) => {
                                    return (
                                        <Input style={{ height: '5rem' }}
                                            type="text"
                                            {...field}
                                            name="firstName" />
                                    )
                                }} />
                            {errors.firstName && <p className="errorMessage">{errors.firstName.message}</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last name</label>
                            <Controller name="lastName" control={control}
                                render={({ field }) => {
                                    return (
                                        <Input style={{ height: '5rem' }}
                                            type="text"
                                            {...field}
                                            name="lastName" />
                                    )
                                }} />
                            {errors.lastName && <p className="errorMessage">{errors.lastName.message}</p>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailAddress">Email address</label>
                        <Controller name="emailAddress" control={control}
                            render={({ field }) => {
                                return (
                                    <Input style={{ height: '5rem' }}
                                        type="email"
                                        {...field}
                                        name="emailAddress" />
                                )
                            }} />
                        {errors.emailAddress && <p className="errorMessage">{errors.emailAddress.message}</p>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone number</label>
                        <Controller name="phoneNumber" control={control}
                            render={({ field }) => {
                                return (
                                    <Input type="tel" style={{ height: '5rem' }}
                                        {...field}
                                        name="phoneNumber" />
                                )
                            }} />
                        {errors.phoneNumber && <p className="errorMessage">{errors.phoneNumber.message}</p>}
                    </div>
                    <div style={{ display: 'block', marginTop: '30px' }}>

                    </div>
                    {
                        !submitLTIInfo ?
                            <button className="btn_red">Register Now</button>
                            :
                            <button disabled className="btn_red">Registering... Please Wait <Spin indicator={antIcon} /></button>
                    }
                </form>
            </Modal>
            <Footer margin={true} />
        </div>
    )
}

export default Trainings;