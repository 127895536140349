import React from "react";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useLayoutEffect } from 'react';

// import Nav from "./utils/nav";
import Nav from "./utils/nav";
import AppRoute from "./utils/routes";

import Homepage from "./components/homepage/index";

// classes starts
import TrainingProgrammes from './components/mini/education/trainings';
import LTI from './components/mini/education/lti';
import Tafta from './components/mini/education/tafta';

// events starts
import Events from './components/mini/events/events';
import Explore from "./components/mini/events/explore";

import GeneralInfo from './components/mini/visits/visit';
import Visit from './components/mini/visits/how_to';
import Rent from './components/mini/visits/rent';
import About from './components/mini/about/about';
import Team from './components/mini/about/team';
import Director from './components/mini/about/director';

// donate starts
import Donate from './components/mini/support/donate';
import HowToGive from './components/mini/support/how';
import Plans from './components/mini/support/plans';

import Shop from './components/shop/shop';
import NewsRoom from './components/mini/newsletter';
import EventTickets from "./components/mini/events/tickets";
import AGT from "./components/mini/new_events/agt";
import NEWONE from "./components/mini/new_events/agt2";
import _5DayEventCalender from "./components/mini/new_events/5days";

const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

const Navigator = () => {
    return (
        <div>
            <BrowserRouter>
                {/* <Nav /> */}
                <Wrapper>
                    <Routes>
                        <Route path={AppRoute.home} exact element={<Homepage />} />
                        {/* classess begins */}
                        <Route path={AppRoute.trainings} exact element={<TrainingProgrammes />} />
                        <Route path={AppRoute.lti} exact element={<LTI />} />
                        <Route path={AppRoute.tafta} exact element={<Tafta />} />
                        {/* classes ends */}
                        <Route path={AppRoute.visit} exact element={<Visit />} />
                        <Route path={AppRoute.generalInfo} exact element={<GeneralInfo />} />
                        <Route path={AppRoute.rent} exact element={<Rent />} />
                        <Route path={AppRoute.about} exact element={<About />} />
                        <Route path={AppRoute.team} exact element={<Team />} />
                        <Route path={AppRoute.director} exact element={<Director />} />
                        {/* events */}
                        <Route path={AppRoute.events} exact element={<Events />} />
                        <Route path={`${AppRoute.tickets}/:eventId/:eventKey/:eventTitle`} exact element={<EventTickets />} />
                        <Route path={AppRoute.explore} exact element={<Explore />} />

                        <Route path={AppRoute.donate} exact element={<Donate />} />
                        <Route path={AppRoute.howToGive} exact element={<HowToGive />} />
                        <Route path={AppRoute.plans} exact element={<Plans />} />

                        <Route path={AppRoute.shop} exact element={<Shop />} />
                        <Route path={AppRoute.newsroom} exact element={<NewsRoom />} />

                        <Route path="/agtextra" exact element={<NEWONE />} />
                        <Route path="/agt" exact element={<AGT />} />
                        <Route path="/5daysevent" exact element={<_5DayEventCalender />} />

                    </Routes>
                </Wrapper>
            </BrowserRouter>
        </div>
    )
}

export default Navigator;