import "../mini.css";

import React from "react";

import Footer from "../../../utils/footer";

import { Divider } from "antd";

import SideNav from "../../../utils/sidenav";
import Nav from "../../../utils/nav";

const OurTeam = () => {
    return (
        <div className="team visit">
            <Nav />
            <div className="about_sect team_display">
                <h2>Administrative Staff</h2>
            </div>
            <div className="contain team_details">
                <div>
                    <div className="grid_2_bias mt-5">
                        <div className="grid_block_right">
                            <div className="mini_story">
                                <div className="mt-5">
                                    <div className="mini_story">
                                        <div className="team">
                                            <h5 className="page_summary" style={{ textAlign: 'center', marginBottom: 0 }}>Advisory</h5>
                                            <Divider style={{ marginTop: 0 }} />
                                            <div className="single">
                                                <div className="team_img_cover team_card team1"></div>
                                                <div className="team_text">
                                                    <h4>His Excellency Babajide Olusola Sanwo-Olu</h4>
                                                    <p>The 15th governor of Lagos State</p>
                                                </div>
                                            </div>
                                            <div className="grid_3">
                                                <div>
                                                    <div className="team_img_cover team_card team2"></div>
                                                    <div className="team_text">
                                                        <h4>His Excellency Femi Hamzat</h4>
                                                        <p>Deputy Governor of Lagos State</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card team3"></div>
                                                    <div className="team_text">
                                                        <h4>Pharm. (Mrs.) Uzamat Akinbile-Yussuf</h4>
                                                        <p>Commissioner, Ministry of Tourism, Arts and Culture</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card team4">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <ion-icon name="logo-instagram"></ion-icon>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Mrs. Oyinade Nathan-Marsh</h4>
                                                        <p>Permanent Secretary, Ministry of Tourism, Arts and Culture</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card team5">
                                                        <div
                                                            className="site_bg_overlay">
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Mr Abimbola Ogunsote</h4>
                                                        <p>Director of Theatres</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="page_summary" style={{ textAlign: 'center', marginBottom: 0, marginTop: 40 }}>Administrative / Artistic / Technical</h5>
                                            <Divider style={{ marginTop: 0 }} />
                                            <div className="single">
                                                <div className="team_img_cover team_card _1">
                                                    <div
                                                        className="site_bg_overlay">
                                                        <div>
                                                            <p></p>
                                                        </div>
                                                        <ion-icon name="logo-instagram"></ion-icon>
                                                    </div>
                                                </div>
                                                <div className="team_text">
                                                    <h4>Mr Bimbo Obafunwa</h4>
                                                    <p style={{ marginBottom: 0 }}>Director</p>
                                                    <p>( Corporate Dance World and Entertainment )</p>
                                                </div>
                                                <div className="">

                                                </div>
                                            </div>
                                            <div className="grid_4">
                                                <div>
                                                    <div className="team_img_cover team_card _3">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Bukola-Oladele</h4>
                                                                <p>Business Development Management</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>Project Manager, Architect, Agile Scrum Master</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Bukola-Oladele</h4>
                                                        <p>Business Development Management</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _4">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Olajumoke Raliat Sidiku</h4>
                                                                <p>Admin / PR Officer</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>Events Management, Hostess / Ushering Management</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Olajumoke Raliat Sidiku</h4>
                                                        <p>Admin / PR Officer</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _5">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Sandra Anuoluwapo Ibhafidon</h4>
                                                                <p>Admin</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>An Entrepreneur, Fine artist.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Sandra Anuoluwapo Ibhafidon</h4>
                                                        <p>Admin</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _6">
                                                        <div Adenike
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Adedoyin Adenike</h4>
                                                                <p>Admin / Accountant</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Adedoyin Adenike</h4>
                                                        <p>Admin/Accountant </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _7">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Segun Alawode</h4>
                                                                <p>Facility Manager</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>Event Planner, Football Manager</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Segun Alawode</h4>
                                                        <p>Facility Manager</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _8">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Godwin Alemeru</h4>
                                                                <p>Logistic Manager</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>Event Planner, Realtor</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Godwin Alemeru  </h4>
                                                        <p>Logistic Manager </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _9">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Kehinde Thompson Adesokan</h4>
                                                                <p>Multimedia Visual Display Specialist</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>Script supervisor(continuity), script writer, content
                                                                    creator, Drama instructor, videographer</p>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Kehinde Thompson Adesokan </h4>
                                                        <p>Multimedia Visual Display Specialist  </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _10">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Adesokan Taiwo Joseph</h4>
                                                                <p>Cinematographer</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>a Photgrapher, CEO T.S Production and Dance
                                                                    Instructor</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Adesokan Taiwo Joseph </h4>
                                                        <p>Cinematographer </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _11">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Elijah Oluwatobilola Ibhafidon</h4>
                                                                <p>Head of Security</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>Graphics and Website Designer, General Printing and
                                                                    Branding</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Elijah Oluwatobilola Ibhafidon  </h4>
                                                        <p>Head of Security </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _12">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Afeez Adeshina Dada</h4>
                                                                <p>Security</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>Fashion Designer, Photographer, Video Editor</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Afeez Adeshina Dada </h4>
                                                        <p>Security </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _13">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Ramoni Ademola Esho</h4>
                                                                <p>Security</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>Mechanical Engineer, Transporter</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Ramoni Ademola Esho</h4>
                                                        <p>Security </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _14">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Rahimot Motunrayo Ibrahim</h4>
                                                                <p>Cleaner</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>Computer Science Graduate, Entrepreneur</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Rahimot Motunrayo Ibrahim </h4>
                                                        <p>Cleaner</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _15">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Adeola Dada Mufutau</h4>
                                                                <p>Cleaner</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>An Entrepreneur</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Adeola Dada Mufutau</h4>
                                                        <p>Cleaner</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card _16">
                                                        <div
                                                            className="site_bg_overlay">
                                                            <div>
                                                                <h4>Rekiat Ajasa</h4>
                                                                <p>Cleaner</p>
                                                                <Divider style={{ margin: 0 }}>Also</Divider>
                                                                <p>An Entrepreneur</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Rekiat Ajasa</h4>
                                                        <p>Cleaner</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card electrician">
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>Precious Grace Communications</h4>
                                                        <p>Lighting </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="team_img_cover team_card accounting">
                                                    </div>
                                                    <div className="team_text">
                                                        <h4>SME accounting services</h4>
                                                        <p>Accounting </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SideNav />
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default OurTeam;